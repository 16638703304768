import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { axiosInstance, axiosKakaopageInstance } from "@/plugins/axios";
import store from "@/store";
import { envs } from "@/const/dotenv";
import i18n from "@/plugins/vue-i18n";
import type { Nullable } from "@/common/types";
import type { KakaopageUserToken } from "@/store/modules/kakaopage-auth";
import endpoints from "@/const/endpoints";
import { useIntervalFn } from "@vueuse/core";
import { useSnackStore } from "@/store/modules/snack";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/modules/auth";
import { useServiceLanguageStore } from "@/store/modules/service-language";
import { useKakaopageAuthStore } from "@/store/modules/kakaopage-auth";
import { stringify } from "qs";

export interface ApiResult<T> {
  success: boolean;
  status?: number;
  data?: T;
  errorMessage?: string;
}

function getErrorMessage(data: unknown, defaultMessage: string) {
  const _data = data as {
    detail:
      | string
      | { loc: string[]; msg: string }[]
      | { errors: { errorCode: string; errorMessage: string }[] }; // 통합리딤코드 메시지 detail 타입
  };
  if (_data?.detail) {
    if (typeof _data?.detail === "string") {
      return _data.detail ?? defaultMessage;
    } else if (Array.isArray(_data.detail)) {
      return (
        _data.detail?.map(
          (d: { loc: string[]; msg: string }) => `${d.loc[1]}=>${d.msg}`,
        )?.[0] ?? defaultMessage
      );
    } else if (_data.detail.errors?.[0]?.errorMessage) {
      return _data.detail.errors?.[0]?.errorMessage ?? defaultMessage;
    }
  }
  return defaultMessage;
}

export function getErrorResult<T, R = T>(error: AxiosError): ApiResult<R> {
  console.error(error.config);

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error);
    return {
      success: false,
      status: error.response.status,
      data: error.response.data as R,
      errorMessage: getErrorMessage(error.response.data, error.message),
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
    return {
      success: false,
      errorMessage: "The request was made but no response was received!",
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error", error.message);
    return {
      success: false,
      errorMessage: error.message,
    };
  }
}

const { pushSnack } = useSnackStore(store);

export function resultSnackbar(result: ApiResult<unknown>): void {
  if (result.success) {
    let successMessage;
    if (result.status === 200) {
      successMessage = i18n.t("msg.successfullyUpdated").toString();
    } else if (result.status === 201) {
      successMessage = i18n.t("msg.successfullyCreated").toString();
    } else if (result.status === 204) {
      successMessage = i18n.t("msg.successfullyDeleted").toString();
    } else {
      console.warn(`status: ${result.status}`);
      successMessage = i18n.t("msg.success").toString();
    }

    pushSnack({
      color: "success",
      text: successMessage,
    });
  } else {
    pushSnack({
      color: "error",
      text: result.errorMessage ?? "",
    });
  }
}

export async function getDataApi<T = never, R = T>(
  url: string,
  filters?: Record<string, string[]>,
): Promise<ApiResult<R>> {
  try {
    const queryParams = filters ? `?${stringifyParams(filters)}` : "";
    const response = await axiosInstance.get<T, AxiosResponse<R>>(
      `${url}${queryParams}`,
    );
    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return getErrorResult(error as AxiosError);
  }
}

export async function createDataApi<T = never, R = T>(
  url: string,
  data: T,
  params?: Record<string, string[]>,
): Promise<ApiResult<R>> {
  try {
    const queryParams = params ? `?${stringifyParams(params)}` : "";
    const response = await axiosInstance.post<T, AxiosResponse<R>>(
      `${url}${queryParams}`,
      data,
    );
    // if success then response.status === 201
    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return getErrorResult(error as AxiosError);
  }
}

export async function updateDataApi<T = never, R = T>(
  url: string,
  data: T,
  params?: Record<string, string[]>,
): Promise<ApiResult<R>> {
  try {
    const queryParams = params ? `?${stringifyParams(params)}` : "";
    const response = await axiosInstance.put<T, AxiosResponse<R>>(
      `${url}${queryParams}`,
      data,
    );
    // if success then response.status === 200
    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return getErrorResult(error as AxiosError);
  }
}

export async function patchDataApi<T = never, R = T>(
  url: string,
  data: Partial<T>,
): Promise<ApiResult<R>> {
  try {
    const response = await axiosInstance.patch<T, AxiosResponse<R>>(url, data);
    // if success then response.status === 200
    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return getErrorResult(error as AxiosError);
  }
}

export async function deleteDataApi<T = never, R = T>(
  url: string,
): Promise<ApiResult<R>> {
  try {
    const response = await axiosInstance.delete<T, AxiosResponse<R>>(url);
    // if success then response.status === 204
    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return getErrorResult(error as AxiosError);
  }
}

export interface PagingListData<T> {
  results: T[];
  count: number;
}

export interface PagingListParams {
  page?: number;
  sortBy?: string[];
  sortDesc?: boolean[];
  itemsPerPage?: number;
  search?: string;
  filters?: Record<string, string[]>;
}

export async function getPagingListApi<T, R = T>({
  url,
  page = 1,
  sortBy = ["id"],
  sortDesc = [true],
  itemsPerPage = 20,
  search = "",
  filters = {},
}: { url: string } & PagingListParams): Promise<ApiResult<PagingListData<R>>> {
  const ordering = sortBy
    .map((sortBy, index) => `${sortDesc[index] ? "-" : ""}${sortBy}`)
    .join();

  const queryParams = stringifyParams({
    page: page,
    page_size: itemsPerPage,
    ordering: ordering,
    search: search,
    ...filters,
  });

  try {
    const response = await axiosInstance.get<PagingListData<R>>(
      `${url}?${queryParams}`,
    );
    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return getErrorResult(error as AxiosError);
  }
}

const { accessToken } = storeToRefs(useAuthStore(store));
const { lang: serviceLang } = useServiceLanguageStore(store);

export async function downloadFileApi(
  url: string,
  contentType: string,
  filename?: string,
): Promise<void> {
  const response = await axios
    .create({
      baseURL: envs.GATEWAY_URL,
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + accessToken.value,
        "Content-Type": contentType,
        Language: serviceLang,
      },
    })
    // eslint-disable-next-line
    .get<any>(url);
  const newUrl = window.URL.createObjectURL(
    new Blob([response.data], { type: response.headers["content-type"] }),
  );
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = newUrl;
  if (filename) {
    tempLink.setAttribute("download", filename);
  } else if (response.headers && response.headers["content-disposition"]) {
    tempLink.setAttribute(
      "download",
      response.headers["content-disposition"]
        ?.split("=")
        ?.pop()
        ?.split(";")
        ?.join("") || "",
    );
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(newUrl);
  return response.data;
}

export async function postDataApi<T, R>(
  url: string,
  data: T,
): Promise<ApiResult<R>> {
  try {
    const response = await axiosInstance.post<T, AxiosResponse<R>>(url, data);
    // if success then response.status === 201
    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return getErrorResult(error as AxiosError);
  }
}

export function pollingApi(
  url: string,
  successFunction: () => void,
  failure403Function?: () => void,
  timeoutFunction?: () => void,
  intervalMilliseconds = 1500,
  attemptNumber = 999,
) {
  let counter = 0;
  const { pause, isActive, resume } = useIntervalFn(async () => {
    try {
      const response = await axios.head(url, {
        withCredentials: true,
      });
      if (response.status === 200) {
        pause();
        successFunction();
        return;
      }
    } catch (error) {
      // 403 응답일 경우는 재시도. 아닌 경우 clear
      if (![403, 404].includes((error as AxiosError)?.response?.status ?? 0)) {
        pause();
        failure403Function && failure403Function();
        return;
      }
    }
    if (counter++ > attemptNumber) {
      pause();
      timeoutFunction && timeoutFunction();
    }
  }, intervalMilliseconds);
  return { pause, isActive, resume };
}
// 추후 삭제
export function pollingApiWithoutIntervalProcessing(
  url: string,
  successFunction: () => void,
  failure403Function?: () => void,
  timeoutFunction?: () => void,
  intervalMilliseconds = 1500,
  attemptNumber = 999,
) {
  let counter = 0;
  return setInterval(async () => {
    try {
      const response = await axios.head(url, {
        withCredentials: true,
      });
      if (response.status === 200) {
        successFunction();
        return;
      }
    } catch (error) {
      // 403 응답일 경우는 재시도. 아닌 경우 clear
      if (![403, 404].includes((error as AxiosError)?.response?.status ?? 0)) {
        failure403Function && failure403Function();
        return;
      }
    }
    if (counter++ > attemptNumber) {
      timeoutFunction && timeoutFunction();
    }
  }, intervalMilliseconds);
}

export async function getKakaopageDataApi<T>(
  url: string,
): Promise<ApiResult<T>> {
  try {
    const response = await axiosKakaopageInstance.get(url);

    return {
      success: true,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    return getErrorResult(error as AxiosError);
  }
}

const {
  expires,
  createdDateTime,
  accessToken: kakaopageAccessToken,
  tokenType,
} = storeToRefs(useKakaopageAuthStore(store));
const { setKakaopageUserToken } = useKakaopageAuthStore(store);

function isExpiredKkpAccessToken(): boolean {
  return +(expires.value || "0") - 300000 < Date.now() - createdDateTime.value;
}

function isKakaopageTokenAlive(): boolean {
  return !!kakaopageAccessToken.value && !isExpiredKkpAccessToken();
}

export async function createKakaopageAccessToken(): Promise<
  Nullable<KakaopageUserToken>
> {
  const result = await createDataApi(endpoints.KAKAOPAGE.authToken, null);

  if (result.success && result.data) {
    setKakaopageUserToken(result.data);
    return result.data as KakaopageUserToken;
  } else {
    resultSnackbar(result);
    return null;
  }
}

export async function createKkpAccessToken(): Promise<
  Nullable<KakaopageUserToken>
> {
  if (isKakaopageTokenAlive()) {
    return {
      tokenType: tokenType.value,
      accessToken: kakaopageAccessToken.value,
      expires: expires.value,
      createdDateTime: createdDateTime.value,
    };
  }

  return await createKakaopageAccessToken();
}

export function stringifyParams(obj: object): string {
  return stringify(
    Object.keys(obj)
      .filter((key) => typeof obj[key] !== "string" || obj[key].trim() !== "")
      .reduce((prev, key) => {
        prev[key] = obj[key];
        return prev;
      }, {}),
    { arrayFormat: "repeat", skipNulls: true },
  );
}
