export default Object.freeze({
  en: {
    badge: "Badge",
    close: "Close",
    dataIterator: {
      noResultsText: "No matching records found",
      loadingText: "Loading items...",
    },
    dataTable: {
      itemsPerPageText: "Rows per page:",
      ariaLabel: {
        sortDescending: "Sorted descending.",
        sortAscending: "Sorted ascending.",
        sortNone: "Not sorted.",
        activateNone: "Activate to remove sorting.",
        activateDescending: "Activate to sort descending.",
        activateAscending: "Activate to sort ascending.",
      },
      sortBy: "Sort by",
    },
    dataFooter: {
      itemsPerPageText: "Items per page:",
      itemsPerPageAll: "All",
      nextPage: "Next page",
      prevPage: "Previous page",
      firstPage: "First page",
      lastPage: "Last page",
      pageText: "{0}-{1} of {2}",
    },
    datePicker: {
      itemsSelected: "{0} selected",
      nextMonthAriaLabel: "Next month",
      nextYearAriaLabel: "Next year",
      prevMonthAriaLabel: "Previous month",
      prevYearAriaLabel: "Previous year",
    },
    noDataText: "No data available",
    carousel: {
      prev: "Previous visual",
      next: "Next visual",
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}",
      },
    },
    calendar: {
      moreEvents: "{0} more",
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Pagination Navigation",
        next: "Next page",
        previous: "Previous page",
        page: "Goto Page {0}",
        currentPage: "Current Page, Page {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "Rating {0} of {1}",
      },
    },
  },
  ko: {
    badge: "뱃지",
    close: "닫기",
    dataIterator: {
      noResultsText: "일치하는 결과를 찾을 수 없습니다.",
      loadingText: "로딩 중...",
    },
    dataTable: {
      itemsPerPageText: "페이지 당 행:",
      ariaLabel: {
        sortDescending: "내림차순 정렬",
        sortAscending: "오름차순 정렬",
        sortNone: "정렬되지 않음",
        activateNone: "소팅을 제거하기 위해 활성화해주세요.",
        activateDescending: "내림차순 정렬을 위해 활성화해주세요.",
        activateAscending: "오름차순 정렬을 위해 활성화해주세요.",
      },
      sortBy: "정렬 방식",
    },
    dataFooter: {
      itemsPerPageText: "페이지 당 아이템 수:",
      itemsPerPageAll: "전체",
      nextPage: "다음 페이지",
      prevPage: "이전 페이지",
      firstPage: "첫번째 페이지",
      lastPage: "마지막 페이지",
      pageText: "{2} 중 {0}-{1}",
    },
    datePicker: {
      itemsSelected: "{0}개 선택됨",
      nextMonthAriaLabel: "다음 달",
      nextYearAriaLabel: "내년",
      prevMonthAriaLabel: "이전 달",
      prevYearAriaLabel: "전년",
    },
    noDataText: "데이터가 없습니다.",
    carousel: {
      prev: "이전",
      next: "다음",
      ariaLabel: {
        delimiter: "슬라이드 {1}개 중 {0} ",
      },
    },
    calendar: {
      moreEvents: "{0}개 더보기",
    },
    fileInput: {
      counter: "파일 {0}개",
      counterSize: "파일 {0}개 (전체 {1}개)",
    },
    timePicker: {
      am: "오전",
      pm: "오후",
    },
    pagination: {
      ariaLabel: {
        wrapper: "페이징",
        next: "다음 페이지",
        previous: "이전 페이지",
        page: "페이지 {0}으로 이동",
        currentPage: "현재 페이지 {0}",
      },
    },
    rating: {
      ariaLabel: {
        icon: "평점 {1} 중 {0}",
      },
    },
  },
});
