export default Object.freeze({
  en: {
    required: "Required field!",
    minLength: "Not enough characters.",
    maxLength: "You can enter up to {max} characters.",
    maxLengthHalfSpace:
      "You can enter up to {max} characters. (space is treated half character)",
    alpha: "Enter only alphabets.",
    alphaNum: "Enter only alphanumeric characters.",
    numeric: "Enter only numeric values.",
    integer: "Enter only integers.",
    decimal: "Enter only numeric values with decimal.",
    email: "Please enter a valid email address.",
    phone: "Please enter a valid phone number.",
    seoId: "Please enter a valid SEO ID.",
    url: "Please enter a valid URL.",
    sameAs: "Passwords do not match.",
    password:
      "Password must include capital and lower case letters, numbers, and special characters (!@#$%^&*-).",
    numericDot: "Enter only numbers and dots(.)",
    betweenDate: "Please check the date range.",
    minRow: "Please enter at least {min} lines.",
    maxRow:
      "Up to {max} line breaks are allowed. Please check the maximum line limit.",
    noSpace: "Please do not include spaces.",
    row: "{num} line(s) must be entered.",
    fileName: "Incorrect file name!",
    minArrayLength: "Minimum {min} must be entered.",
    maxArrayLength: "Maximum {max} could be entered.",
    minRowLength: "At least {min} rows are required.",
    maxRowLength: "A maximum of {max} rows can be entered.",
    maxValue: "{max} maximum",
    minValue: "{min} minimum",
    valueMaxLengthAndRowRequiredLength:
      "You can write up to {maxLength} characters per line including spaces, and you must write {minRow} lines out of the maximum {maxRow} lines.",
    maxEachLength:
      "You can write up to {max} characters per line including spaces",
    maxEachLengthAndRowsRequired:
      "You can write up to {maxLength} characters per line including spaces, and you must write {row} lines.",
    maxEachLengthAndRowsMax:
      "You can write up to {maxLength} characters per line including spaces, and you cam write {row} lines.",
    colorCode: "Must be between #000000 and #FFFFFF.",
    selected: "{num} items must be selected",
    iosVersion:
      "구분점 2개, 구분점 사이에 3자리수까지 가능합니다.(nnn.nnn.nnn)",
    androidVersion:
      "구분점 3개, 구분점 사이에 2자리수까지 가능합니다.(nn.nn.nn.nn)",
  },
  ko: {
    required: "필수 항목입니다!",
    minLength: "글자 수가 모자랍니다!",
    maxLength: "{max}자 까지 입력 가능합니다!",
    maxLengthHalfSpace: "{max}자까지 입력 가능합니다. (공백은 0.5자)",
    alpha: "알파벳만 입력 가능합니다!",
    alphaNum: "알파벳/숫자만 입력 가능합니다!",
    numeric: "수(numeric)만 입력 가능합니다!",
    integer: "정수(integer)만 입력 가능합니다!",
    decimal: "10진수(decimal)만 입력 가능합니다!",
    email: "이메일 형식을 확인하세요!",
    phone: "전화번호 형식을 확인하세요!",
    seoId: "SEO ID 형식을 확인하세요!",
    url: "URL 형식을 확인하세요!",
    sameAs: "패스워드가 똑같지 않습니다!",
    password:
      "패스워드에 대문자, 소문자, 숫자, 특수문자(!@#$%^&*-)가 포함되어 있어야 합니다!",
    numericDot: "숫자와 점(.) 으로만 작성해주세요.",
    betweenDate: "날짜 범위 설정을 확인하세요!",
    minRow: "최소 {min}줄 이상 작성해주세요.",
    maxRow: "최대 {max}줄 줄바꿈까지 가능합니다. 최대 줄 제한을 확인해주세요.",
    noSpace: "공백은 허용하지 않습니다!",
    row: "{num}줄이 입력되어야 합니다!",
    fileName: "파일 이름이 올바르지 않습니다!",
    minArrayLength: "최소 {min} 개 이상 입력되어야 합니다.",
    maxArrayLength: "최대 {max} 개 까지만 입력 가능합니다.",
    minRowLength: "최소 {min} 줄 이상 입력되어야 합니다.",
    maxRowLength: "최대 {max} 줄 까지만 입력 가능합니다.",
    maxValue: "최대 {max} 이하만 가능합니다.",
    minValue: "최소 {min} 이상만 가능합니다.",
    valueMaxLengthAndRowRequiredLength:
      "1줄 당 공백 포함 최대 {maxLength}자 이내 작성 가능하며, 최대 {maxRow}줄 중 {minRow}줄 작성은 필수입니다.",
    maxEachLength: "1줄 당 공백 포함 최대 {max}자 이내 작성가능합니다.",
    maxEachLengthAndRowsRequired:
      "1줄당 공백 포함 최대 {maxLength}자 이내 작성 가능하며, {row}줄 작성 필수 입니다.",
    maxEachLengthAndRowsMax:
      "1줄당 공백 포함 최대 {maxLength}자 이내 작성 가능하며, {row}줄까지 작성 가능합니다.",
    colorCode: "#000000와 #FFFFFF 사이의 값이어야 합니다",
    selected: "{num}개의 아이템이 선택되어있어야 합니다",
    iosVersion:
      "구분점 2개, 구분점 사이에 3자리수까지 가능합니다.(nnn.nnn.nnn)",
    androidVersion:
      "구분점 3개, 구분점 사이에 2자리수까지 가능합니다.(nn.nn.nn.nn)",
  },
});
