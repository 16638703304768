import { ConfigByServiceLanguage } from "@/const/service/language";
import type { CardLabel } from "@/views/sms/section/common/types/featured";
import type { ChoiceItem } from "@/common/types";
import type { MaxLength } from "@/const/service/common/types";

export class Taiwanese extends ConfigByServiceLanguage {
  getGenreChoices(): ChoiceItem<string>[] {
    return [
      { text: "奇幻", value: "FANTASY" },
      { text: "奇幻愛情", value: "FANTASY_ROMANCE" },
      { text: "動作冒險", value: "ACTION" },
      { text: "愛情", value: "SUNJUNG_ROMANCE" },
      { text: "劇情", value: "DRAMA" },
      { text: "BL/GL", value: "BL_GL" },
      { text: "懸疑恐怖", value: "THRILLER" },
    ];
  }

  getCardLabels(): CardLabel[] {
    return [
      // RED
      {
        text: "新作",
        backgroundColor: "#FF3042",
      },
      {
        text: "即將完結",
        backgroundColor: "#FF3042",
      },
      {
        text: "戲劇原著",
        backgroundColor: "#FF3042",
      },
      {
        text: "新一季上架",
        backgroundColor: "#FF3042",
      },
      {
        text: "人氣",
        backgroundColor: "#FF3042",
      },
      {
        text: "近期上架",
        backgroundColor: "#FF3042",
      },
      {
        text: "經典",
        backgroundColor: "#FF3042",
      },
      {
        text: "完結",
        backgroundColor: "#FF3042",
      },
      {
        text: "電影原著",
        backgroundColor: "#FF3042",
      },
      {
        text: "季完結",
        backgroundColor: "#FF3042",
      },
      // GOLD
      {
        text: "活動",
        backgroundColor: "#C6A66D",
      },
      {
        text: "新作搶先看",
        backgroundColor: "#C6A66D",
      },
      {
        text: "限時活動",
        backgroundColor: "#C6A66D",
      },
      {
        text: "完結活動",
        backgroundColor: "#C6A66D",
      },
      {
        text: "預約",
        backgroundColor: "#C6A66D",
      },
      {
        text: "今日限定",
        backgroundColor: "#C6A66D",
      },
      {
        text: "編輯推薦",
        backgroundColor: "#C6A66D",
      },
      {
        text: "新作上架",
        backgroundColor: "#C6A66D",
      },
      {
        text: "本週限定",
        backgroundColor: "#C6A66D",
      },
      {
        text: "爆更",
        backgroundColor: "#C6A66D",
      },
    ];
  }

  getCompletedTabLabels(): CardLabel[] {
    return [
      { text: "經典作品", backgroundColor: "#FF3042" }, // 레전드
      { text: "PD推薦", backgroundColor: "#FF3042" }, // PD추천
      { text: "電影預告", backgroundColor: "#FF3042" }, // 영화 예고편
      { text: "推薦", backgroundColor: "#FF3042" }, // 추천
      { text: "追漫誌", backgroundColor: "#FF3042" }, // 매거진
      { text: "借閱券", backgroundColor: "#FF3042" }, // 대여권
      { text: "金幣回饋", backgroundColor: "#FF3042" }, // 캐시백
    ];
  }

  getAvailableSectionList(): string[] {
    return [
      "top_hot_main",
      "top_hot_normal",
      "gidamoo_plus_hot",
      "main_featured",
      "main_featured_last",
      "gidamoo_plus_new",
      "main_gidamoo",
      "main_special",
      "channel_mon",
      "channel_tue",
      "channel_wed",
      "channel_thu",
      "channel_fri",
      "channel_sat",
      "channel_sun",
      "channel_completed",
      "ad_event_viewer",
      "ad_content_home",
      "ad_rank_all",
      "timetable_mon",
      "timetable_tue",
      "timetable_wed",
      "timetable_thu",
      "timetable_fri",
      "timetable_sat",
      "timetable_sun",
      "timetable_completed",
    ];
  }

  getContentCatchphraseMaxLength(): MaxLength {
    return {
      threeLines: 15,
      twoLines: 20,
    };
  }

  getIpPromotionVideoTitleMaxLength(): number {
    return 12;
  }

  getIpPromotionVideoSubtitleMaxLength(): number {
    return 19;
  }

  getSpecialMatchingTitleMaxLength(): number {
    return 20;
  }

  getCustomLabelMaxLength(): number {
    return 8;
  }

  getCampaignPageTitleMaxLength(): number {
    return 13;
  }
  getCampaignPageModuleTitleMaxLength(): number {
    return 16;
  }
  getCampaignPageModuleTitleRowLength(): number {
    return 2;
  }
  getCampaignPageModuleSubTitleMaxLength(): number {
    return 23;
  }
  getCampaignPageModuleSubTitleRowLength(): number {
    return 4;
  }
  getRewardCautionInCampaignNoticeModule(): string {
    return "任務完成時將於一天內發送獎勵，詳情請於「通知」＞「我的消息」中確認";
  }
  getEventCautionInCampaignNoticeModule(): string {
    return (
      "本活動自開始日21:00起開跑，第一話已經搶先看的用戶，請於活動期間再次點擊話次，即可列入計算\n" +
      "閱讀與否之判定以進入話次閱讀畫面為準\n" +
      "僅購買閱讀券未使用，僅下載而未閱讀或於離線模式閱讀的話次皆不列入計算\n" +
      "本活動僅限註冊用戶參加\n" +
      "本活動之獎品不得轉換、轉讓或折讓現金\n" +
      "本活動發送的免費金幣於效期內未使用將自動失效"
    );
  }
}
