import axios from "axios";
import type { AxiosInstance } from "axios";
import { envs } from "@/const/dotenv";
import store from "@/store";
import { useAuthStore } from "@/store/modules/auth";
import { storeToRefs } from "pinia";
import { useServiceLanguageStore } from "@/store/modules/service-language";
import { useKakaopageAuthStore } from "@/store/modules/kakaopage-auth";

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: envs.GATEWAY_URL,
});

export const axiosKakaopageInstance: AxiosInstance = axios.create({
  baseURL: envs.GATEWAY_URL,
});

const { accessToken, refreshToken } = storeToRefs(useAuthStore(store));
const { staffId, clearToken, setToken } = useAuthStore(store);
const { lang: serviceLanguage } = useServiceLanguageStore(store);

// 모든 요청마다 헤더에 토큰 추가
axiosInstance.interceptors.request.use(
  (config) => {
    const token = accessToken.value;
    if (config.headers) {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.headers["Language"] = serviceLanguage;
      config.headers["Staff-Id"] = staffId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 토큰 갱신이 필요하면 갱신 후 재요청
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status !== 401 ||
      error.response?.data?.detail !== "ACCESS_TOKEN_EXPIRED"
    ) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (!originalRequest._retry) {
      // refreshToken 으로 accessToken 갱신 시도
      originalRequest._retry = true;
      const _refreshToken = refreshToken.value;

      if (!_refreshToken) {
        // refreshToken 이 없는 경우
        clearToken();
        return Promise.resolve(error);
      }

      return generateToken(_refreshToken)
        .then((res) => {
          if (res.status !== 200) {
            return Promise.reject(error);
          }

          setToken(res.data);
          axiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken.value;
          return axiosInstance(originalRequest);
        })
        .catch(function (error) {
          clearToken();
          return Promise.resolve(error);
        });
    }

    return Promise.reject(error);
  },
);

const { accessToken: kakaopageAccessToken } = storeToRefs(
  useKakaopageAuthStore(store),
);

axiosKakaopageInstance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers["Authorization"] = "Bearer " + accessToken.value;
      config.headers["Language"] = serviceLanguage;
      config.headers["Kakaopage-Access-Token"] = kakaopageAccessToken.value;
      config.headers["Staff-Id"] = staffId;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export async function generateToken(refreshToken: string) {
  return axiosInstance.put("/auth/token", {
    token: refreshToken,
  });
}
