<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.thresholds.xs"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @click:outside="close"
  >
    <v-card color="rgb(66,66,66, 0.95)">
      <v-card-title v-text="$t('msg.adminLanguageSettings')" />
      <v-card-text>
        {{ $t("msg.adminLanguageSettingsComment") }}
        <br />
        {{ $t("msg.saveBeforeChange") }}
        <v-row>
          <v-spacer />
          <v-col cols="auto">
            <v-btn outlined @click="close" v-text="$t('msg.cancel')" />
          </v-col>
          <v-col cols="auto">
            <v-btn
              outlined
              color="create"
              @click="setLanguage"
              v-text="$t('msg.set')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref } from "vue";
import i18n from "@/plugins/vue-i18n";
import { useAdminLanguageStore } from "@/store/modules/admin-language";
import { storeToRefs } from "pinia";

const instance = getCurrentInstance();
const dialog = ref(false);
const locale = ref("");

function open(_locale: string): void {
  dialog.value = true;
  locale.value = _locale;
}

function close(): void {
  dialog.value = false;
  locale.value = "";
}

const { lang } = storeToRefs(useAdminLanguageStore());
function setLanguage(): void {
  if (locale.value) {
    i18n.locale = locale.value;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (instance) {
      instance.proxy.$vuetify.lang.current = locale.value;
    }
    lang.value = locale.value ?? "ko";

    close();
    window.location.reload();
  }
}
defineExpose({ open });
</script>
