import type { Channel } from "@/views/cms/content/common/choices";
import type { AgeChoiceItem } from "@/const/service/common/types";
import type { ChoiceItem, ServiceLanguageString } from "@/common/types";
import type { SearchType } from "@/views/member/search/common/choices";
import type { FilterItem } from "@/components/filter/common/types";

export abstract class ServiceRegion {
  abstract getAgeChoices(): AgeChoiceItem[];
  abstract getChannelChoices(): ChoiceItem<Channel>[];
  abstract getAdultAge(): number;
  abstract ch2Enabled(): boolean;
  abstract genreRankingEnabled(): boolean;
  abstract possessionTicketEnabled(): boolean;
  abstract getMemberSearchType(): ChoiceItem<string>[];
  abstract getMemberSearchTypeHint(): Partial<Record<SearchType, string>>;
  abstract getMemberDataShow(): boolean;
  abstract changeNormalBannerTextColorEnabled(): boolean;
  abstract adEnabled(): boolean;
  abstract webToAppEnabled(): boolean;
  abstract bannerEventSpecialCardMaxLength(): number;
  abstract getServiceLanguages(): ServiceLanguageString[];
  abstract combinationBMEnabled(): boolean;
  abstract sendManuscriptToolEnabled(): boolean;
  abstract getContentBMFilterItems(): FilterItem[];
  abstract showReleasedForFreeDateTime(): boolean;
  abstract showKakaopageEpisodeGet(): boolean;
  abstract showKakaopageOriginalNovelContentLink(): boolean;
  abstract gidamooPlusEnabled(): boolean;
  abstract freeTodayEnabled(): boolean;

  abstract freePublishingBadgeEnabled(): boolean;
  abstract getHotMainCardSubTitleMaxLength(): number;
  abstract getHotMainCardTitleTextMaxLength(): number;
  abstract showMemberAgeCredentials(): boolean;
}
