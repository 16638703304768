import type { DateType } from "@/utils/tzDayjs/index";
import type { Path } from "@/utils/path";
import { Choices } from "@/utils/choices";
import i18n from "@/plugins/vue-i18n";

export interface DeleteItem {
  id?: number;
}

export type DateTime = DateType;

export type Nullable<T> = null | T;

export interface ChoiceItem<T> {
  text: string;
  value: T;
}

export type PathLike = string | Path;

export interface AdminLanguage {
  code: string;
  flag: string;
  label: string;
}

export enum Env {
  LOCAL = "local",
  SANDBOX = "sandbox",
  QA = "qa",
  PROD = "prod",
}

export enum ServiceLanguage {
  TH = "th",
  TW = "zht",
  KO = "ko",
  ID = "id",
  EN = "en",
}
export type ServiceLanguageString =
  typeof ServiceLanguage[keyof typeof ServiceLanguage];
export const SERVICE_LANGUAGE = Object.freeze(
  new Choices(ServiceLanguage, {
    [ServiceLanguage.TH]: {
      text: i18n.t("auth.language.TH").toString(),
      value: ServiceLanguage.TH,
    },
    [ServiceLanguage.TW]: {
      text: i18n.t("auth.language.TW").toString(),
      value: ServiceLanguage.TW,
    },
    [ServiceLanguage.KO]: {
      text: i18n.t("auth.language.KO").toString(),
      value: ServiceLanguage.KO,
    },
    [ServiceLanguage.ID]: {
      text: i18n.t("auth.language.ID").toString(),
      value: ServiceLanguage.ID,
    },
    [ServiceLanguage.EN]: {
      text: i18n.t("auth.language.EN").toString(),
      value: ServiceLanguage.EN,
    },
  }),
);
