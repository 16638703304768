import i18n from "@/plugins/vue-i18n";
import { Choices } from "@/utils/choices";

export enum MetadataType {
  SUB_GENRE = "SUB_GENRE",
  EMOTION = "EMOTION",
  STORY_ELEMENT = "STORY_ELEMENT",
  MATERIAL = "MATERIAL",
  DRAWING_STYLE = "DRAWING_STYLE",
}

export const METADATA_TYPE = Object.freeze(
  new Choices(MetadataType, {
    [MetadataType.SUB_GENRE]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.SUB_GENRE").toString(),
      value: MetadataType.SUB_GENRE,
    },
    [MetadataType.EMOTION]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.EMOTION").toString(),
      value: MetadataType.EMOTION,
    },
    [MetadataType.STORY_ELEMENT]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.STORY_ELEMENT").toString(),
      value: MetadataType.STORY_ELEMENT,
    },
    [MetadataType.MATERIAL]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.MATERIAL").toString(),
      value: MetadataType.MATERIAL,
    },
    [MetadataType.DRAWING_STYLE]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.DRAWING_STYLE").toString(),
      value: MetadataType.DRAWING_STYLE,
    },
  }),
);

export enum MetadataTendency {
  MAN = "MAN",
  WOMAN = "WOMAN",
  NOTHING = "NOTHING",
}

export const METADATA_TENDENCY = Object.freeze(
  new Choices(MetadataTendency, {
    [MetadataTendency.WOMAN]: {
      text: i18n.t("cms.metadataItem.METADATA_TENDENCY.WOMAN").toString(),
      value: MetadataTendency.WOMAN,
    },
    [MetadataTendency.MAN]: {
      text: i18n.t("cms.metadataItem.METADATA_TENDENCY.MAN").toString(),
      value: MetadataTendency.MAN,
    },
    [MetadataTendency.NOTHING]: {
      text: i18n.t("cms.metadataItem.METADATA_TENDENCY.NOTHING").toString(),
      value: MetadataTendency.NOTHING,
    },
  }),
);
