import dayjs from "dayjs";
import { envs } from "@/const/dotenv";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import fixedTimezone from "./plugins/fixedTimezone";
import i18n from "@/plugins/vue-i18n";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(envs.DATE_TIME_ZONE);
dayjs.extend(fixedTimezone);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export type DateType = dayjs.ConfigType;
export function tzDayjs(value?: DateType, parseFormat?: string): dayjs.Dayjs {
  return new TzDayjs(value, parseFormat)._dayjs;
}

class TzDayjs {
  public _dayjs: dayjs.Dayjs;
  constructor(value?: DateType, parseFormat = envs.DATETIME_FORMAT_STRING) {
    try {
      this._dayjs =
        typeof value === "string"
          ? isISOString(value)
            ? dayjs(value).tz()
            : parseDayjs(value, parseFormat)
          : dayjs(value).tz();
    } catch (e) {
      console.warn(e);
      console.warn({ value });
      console.warn({ parseFormat });
      this._dayjs = dayjs();
    }
  }
}

const parseDayjs = (value: string, parseFormat: string): dayjs.Dayjs => {
  try {
    // MMM, Do, A, a
    // 위와 같은 포맷을 사용하지 않는다면 'customParseFormat' Plugin 만 이용하여 format, parse 가능하다
    // 하지만 위와 같은 포맷을 사용한다면 'advancedFormat' Plugin 을 이용하여 format, parse 하여야 한다.
    // 'advancedFormat' 을 사용한다면 dayjs.tz() 를 사용하여 parse 하지 못한다.
    // 그 말은 즉 '16.Nov.2020' 같은 날짜를 읽을때 현재 로컬 타임존 기준으로 읽어 올 수 밖에 없다는 것이다.
    // 때문에 위와 같은 포맷을 사용한다면 dayjs() 로 parse 하여 지정된 타임존 과의 Offset 만큼을 더해주거나 빼줘야한다.
    // 심지어 'advancedFormat' 을 사용한다면 'customParseFormat' 를 함께 사용할 수 없다.
    // 위 포맷이 들어가면 'advancedFormat'
    // 위 포맷이 들어가지 않는다면 'customParseFormat'
    // 후에 더 좋은 방법이 있다면 변경해주자.
    if (
      parseFormat.includes("MMM") ||
      parseFormat.includes("Do") ||
      parseFormat.includes("A") ||
      parseFormat.includes("a")
    ) {
      dayjs.extend(advancedFormat);
      return dayjs(value, parseFormat, i18n.locale)
        .add(diffOffset(), "minute")
        .tz();
    } else {
      dayjs.extend(customParseFormat);
      return dayjs.tz(value, parseFormat, envs.DATE_TIME_ZONE);
    }
  } catch (e) {
    if ((e as { message: string }).message.startsWith("Invalid time value")) {
      if (parseFormat === envs.DATETIME_FORMAT_STRING) {
        return parseDayjs(value, envs.DATE_FORMAT_STRING);
      } else if (parseFormat === envs.DATE_FORMAT_STRING) {
        return parseDayjs(value, envs.DATETIME_MINUTE_FORMAT_STRING);
      }
    }
    console.warn(e);
    console.warn({ value });
    console.warn({ parseFormat });
    return dayjs().tz();
  }
};

const isISOString = (str: string): boolean => {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d*Z$/.test(str);
};

const diffOffset = (): number => {
  return dayjs().utcOffset() - dayjs().tz(envs.DATE_TIME_ZONE).utcOffset();
};
