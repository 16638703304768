import { defineStore } from "pinia";

interface AdminLanguage {
  lang: string;
}

export const useAdminLanguageStore = defineStore("adminLanguage", {
  state: (): AdminLanguage => {
    return {
      lang: "ko",
    };
  },
  actions: {},
  persist: true,
});
