import type { ServiceRegion } from "@/const/service/region";
import type { ConfigByServiceLanguage } from "@/const/service/language";
import { Region } from "@/views/cms/content/common/choices";
import { Korean } from "@/const/service/languages/korean";
import { Taiwanese } from "@/const/service/languages/taiwanese";
import { Thai } from "@/const/service/languages/thai";
import { Indonesian } from "@/const/service/languages/indonesian";
import { Korea } from "@/const/service/regions/korea";
import { Taiwan } from "@/const/service/regions/taiwan";
import { Thailand } from "@/const/service/regions/thailand";
import { Indonesia } from "@/const/service/regions/indonesia";
import { envs } from "@/const/dotenv";
import store from "@/store";
import { ServiceLanguage } from "@/common/types";
import { English } from "@/const/service/languages/english";
import { useServiceLanguageStore } from "@/store/modules/service-language";

const { lang } = useServiceLanguageStore(store);
function getServiceLanguage(): ConfigByServiceLanguage {
  const currentServiceLanguage = lang;
  if (currentServiceLanguage === ServiceLanguage.KO) {
    return new Korean();
  } else if (currentServiceLanguage === ServiceLanguage.TW) {
    return new Taiwanese();
  } else if (currentServiceLanguage === ServiceLanguage.TH) {
    return new Thai();
  } else if (currentServiceLanguage === ServiceLanguage.ID) {
    return new Indonesian();
  } else if (currentServiceLanguage === ServiceLanguage.EN) {
    return new English();
  } else {
    return new Korean();
  }
}

function getServiceRegion(): ServiceRegion {
  const currentServiceRegion = envs.SERVICE_REGION_CODE;

  if (currentServiceRegion === Region.KOREA) {
    return new Korea();
  } else if (currentServiceRegion === Region.TAIWAN) {
    return new Taiwan();
  } else if (currentServiceRegion === Region.THAILAND) {
    return new Thailand();
  } else if (currentServiceRegion === Region.INDONESIA) {
    return new Indonesia();
  } else {
    return new Korea();
  }
}

export const serviceLanguage = getServiceLanguage();
export const serviceRegion = getServiceRegion();
