export const envs = {
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN as string,
  GATEWAY_URL: import.meta.env.VITE_GATEWAY_URL as string,
  DATE_TIME_ZONE: import.meta.env.VITE_DATE_TIME_ZONE as string,
  DATE_FORMAT_STRING: import.meta.env.VITE_DATE_FORMAT_STRING as string,
  DATETIME_FORMAT_STRING: import.meta.env.VITE_DATETIME_FORMAT_STRING as string,
  DATETIME_TZ_API_FORMAT_STRING: import.meta.env
    .VITE_DATETIME_TZ_API_FORMAT_STRING as string,
  TIME_MINUTE_FORMAT_STRING: import.meta.env
    .VITE_TIME_MINUTE_FORMAT_STRING as string,
  DATETIME_MINUTE_FORMAT_STRING: import.meta.env
    .VITE_DATETIME_MINUTE_FORMAT_STRING as string,
  PUBLIC_CONTENTS_BUCKET: import.meta.env.VITE_PUBLIC_CONTENTS_BUCKET as string,
  PRIVATE_CONTENTS_BUCKET: import.meta.env
    .VITE_PRIVATE_CONTENTS_BUCKET as string,
  PUBLIC_CONTENTS_BUCKET_ROOT_FOLDER: import.meta.env
    .VITE_PUBLIC_CONTENTS_BUCKET_ROOT_FOLDER as string,
  PRIVATE_DATA_BUCKET: import.meta.env.VITE_PRIVATE_DATA_BUCKET as string,
  CDN_HOST: import.meta.env.VITE_CDN_HOST as string,
  SERVICE_REGION_CODE: import.meta.env.VITE_SERVICE_REGION_CODE as string,
  IMAGE_SERVER_ADDRESS: import.meta.env.VITE_IMAGE_SERVER_ADDRESS as string,
  BILLING_SITE_CODE: import.meta.env.VITE_BILLING_SITE_CODE as string,
  SKIP_AUTH: import.meta.env.VITE_SKIP_AUTH === "true",
  COGNITO_LOGIN_ENDPOINT: `https://${
    import.meta.env.VITE_COGNITO_ENDPOINT
  }/login?client_id=${import.meta.env.VITE_COGNITO_CLIENT_ID}&redirect_uri=${
    import.meta.env.VITE_COGNITO_LOGIN_REDIRECT_URI
  }&response_type=code`,
  COGNITO_LOGOUT_ENDPOINT: `https://${
    import.meta.env.VITE_COGNITO_ENDPOINT
  }/logout?client_id=${import.meta.env.VITE_COGNITO_CLIENT_ID}&logout_uri=${
    import.meta.env.VITE_COGNITO_LOGOUT_REDIRECT_URI
  }&redirect_uri=dummy&response_type=code`,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT as string,
  SERVICE_URL: import.meta.env.VITE_SERVICE_URL as string,
  CLOUD_NAME: import.meta.env.VITE_CLOUD_NAME as "aws" | "gcp",
};
