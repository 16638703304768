export default Object.freeze({
  en: {
    kakaoWebtoon: "Kakao Webtoon",
    id: "ID",
    home: "Home",
    status: "Status",
    now: "Now",
    today: "Today",
    month: "Month",
    week: "Week",
    day: "Day",
    cancel: "Cancel",
    close: "Close",
    confirm: "Confirm",
    approve: "Approve",
    save: "Save",
    delete: "Delete",
    register: "Register",
    schedule: "Schedule",
    type: "Type",
    active: "Enable",
    inactive: "Disable",
    title: "Title",
    subtitle: "Subtitle",
    content: "Content",
    description: "Description",
    landingUrl: "Landing Url",
    image: "Image",
    button: "Button",
    video: "Video",
    created: "Created",
    updated: "Updated",
    userId: "User ID",
    userName: "User name",
    download: "Download",
    activation: "Enable",
    restore: "Restore",
    sort: "Sorting",
    order: "Order",
    thumbnail: "Thumbnail",
    noDataText:
      "This content does not belong to any special matching (collection).",
    notReady: "Preparing",
    notSupportedExtensions: "Unsupported extension (please use PNG, JPG)",
    copyToClipboard: "Copy to Clipboard",
    copied: "Copied",
    set: "Enable",
    unset: "Disable",
    search: "Search",
    choice: "Select",
    fixed: "Fixed",
    maximum: "Maximum",
    unlimited: "Unlimited",
    adminLanguage: "Admin language",
    serviceLanguage: "Service language",
    numeric: {
      day: "Day(s)",
      hour: "Hour(s)",
      count: "count",
      people: "user(s)",
    },
    picker: {
      dateSelection: "Select date",
      startDate: "From",
      endDate: "To",
    },
    filter: "Filter",
    success: "Success!",
    successfullyCreated: "Created!",
    successfullyUpdated: "Updated!",
    successfullyDeleted: "Deleted!",
    confirmDelete: "Are you sure you want to delete?",
    required: "Please enter required value.",
    pleaseFillInAllTheRequiredFields:
      "Please fill out all the required fields.",
    pleaseCheckYourInputData: "Please check your input data!",
    uploadThisMedia: "{label} must be uploaded.",
    atLeastOneContentsInCollection:
      "There must be at least one content in the collection.",
    atLeastOneAuthorOrIllustrator:
      "There must be at least 1 author or illustrator.",
    atLeastOneWeekday: "There must be at least one serial weekdays",
    atLeastOneView: "There must be at least one viewer type",
    selectLabel: "Please select Anchor Label",
    selectMatchingCollection: "Please select Matching Collection",
    dataSelect: {
      content: "Select content",
      season: "Season",
      cp: "Select CP",
      author: "Select author",
      collection: "Select collection",
      episode: "Select episode",
      bridgePage: "Select Special bridge page",
      present: "Select present",
      luckyDraw: "Select lucky draw",
      raffle: "Select raffle",
      campaignPage: "Select event page",
      matchingCollection: "Select matching collection",
      purchaseId: "Select purchase order number",
      notice: "Select notice",
      cognitoUser: "Cognito user",
    },
    saveBeforeChange: "(Please save before making any changes.)",
    adminLanguageSettings: "Admin language settings",
    adminLanguageSettingsComment:
      "Are you sure you want to change admin language?",
    serviceLanguageSettings: "Service language settings",
    serviceLanguageComment: "Are you sure you want to change service language?",
    signIn: "Sign in",
    signOut: "Sign out",
    isNotRecommendedSize: "Not recommended image size",
    imageFileUploaded: "Image file uploaded",
    backgroundImage: "Background image",
    waitForFreeSettingComment: "Please set it to the BM type of waitForFree",
    none: "None",
  },
  ko: {
    kakaoWebtoon: "카카오 웹툰",
    id: "ID",
    home: "홈",
    status: "상태",
    now: "지금",
    today: "오늘",
    month: "월",
    week: "주",
    day: "일",
    cancel: "취소",
    close: "닫기",
    confirm: "확인",
    approve: "승인",
    save: "저장",
    delete: "삭제",
    register: "등록",
    schedule: "스케줄",
    type: "타입",
    active: "활성",
    inactive: "비활성",
    title: "제목",
    subtitle: "부제목",
    content: "내용",
    description: "설명",
    landingUrl: "Landing Url",
    image: "이미지",
    button: "버튼",
    video: "비디오",
    created: "생성일시",
    updated: "수정일시",
    userId: "유저 아이디",
    userName: "유저 명",
    download: "다운로드",
    activation: "활성",
    restore: "되돌리기",
    sort: "정렬",
    order: "순서",
    thumbnail: "썸네일",
    noDataText: "해당 작품이 속한 스페셜 매칭(컬렉션)이 없습니다.",
    notReady: "준비중",
    notSupportedExtensions: "지원하지 않는 확장자(사용가능: 'PNG', 'JPG')",
    copyToClipboard: "클립보드에 복사하기",
    copied: "복사됨",
    set: "설정",
    unset: "미설정",
    search: "검색",
    choice: "선택",
    fixed: "고정",
    maximum: "최대",
    unlimited: "무제한",
    adminLanguage: "어드민 언어",
    serviceLanguage: "서비스 언어",
    numeric: {
      day: "일",
      hour: "시간",
      count: "개",
      people: "명",
    },
    picker: {
      dateSelection: "날짜 선택",
      startDate: "시작 날짜",
      endDate: "종료 날짜",
    },
    filter: "필터",
    success: "성공!",
    successfullyCreated: "생성 성공!",
    successfullyUpdated: "업데이트 성공!",
    successfullyDeleted: "삭제 성공!",
    confirmDelete: "정말 삭제하시겠습니까?",
    required: "필수값을 입력해주세요.",
    pleaseFillInAllTheRequiredFields: "필수 항목을 모두 입력해주세요!",
    pleaseCheckYourInputData: "입력한 값이 유효한지 확인해주세요!",
    uploadThisMedia: "{label}의 업로드가 필수입니다",
    atLeastOneContentsInCollection: "컬렉션에 작품이 최소 1개는 있어야 합니다",
    atLeastOneAuthorOrIllustrator:
      "최소 1명의 글 또는 그림 작가가 있어야 합니다",
    atLeastOneWeekday: "최소 1개의 연재 요일이 선택되어야 합니다.",
    atLeastOneView: "최소 1개의 뷰어가 선택되어야 합니다.",
    selectLabel: "앵커 레이블을 선택해주세요",
    selectMatchingCollection: "매칭 컬렉션을 선택해주세요",
    dataSelect: {
      content: "작품 선택",
      season: "시즌",
      cp: "CP 선택",
      author: "작가 선택",
      collection: "컬렉션 선택",
      episode: "회차 선택",
      bridgePage: "스페셜 브릿지 페이지 선택",
      present: "경품 선택",
      luckyDraw: "뽑기권 선택",
      raffle: "응모권 선택",
      campaignPage: "이벤트 페이지 선택",
      matchingCollection: "매칭 컬렉션 선택",
      purchaseId: "구매번호 선택",
      notice: "공지사항 선택",
      cognitoUser: "Cognito 유저",
    },
    saveBeforeChange: "(변경 전 현재 작업중인 내용을 저장해주세요.)",
    adminLanguageSettings: "운영툴 언어 설정",
    adminLanguageSettingsComment: "정말로 운영툴 언어를 변경하시겠습니까?",
    serviceLanguageSettings: "서비스 언어 설정",
    serviceLanguageComment: "정말로 서비스 언어를 변경하시겠습니까?",
    signIn: "로그인",
    signOut: "로그아웃",
    isNotRecommendedSize: "다른 크기의 이미지가 선택되었습니다",
    imageFileUploaded: "이미지 파일 업로드 완료",
    backgroundImage: "배경 이미지",
    waitForFreeSettingComment: "기다무 BM 타입으로 먼저 설정해주세요.",
    none: "해당 없음",
  },
});
