import type { CardLabel } from "@/views/sms/section/common/types/featured";
import type { ChoiceItem } from "@/common/types";
import type { MaxLength } from "@/const/service/common/types";

export abstract class ConfigByServiceLanguage {
  abstract getGenreChoices(): ChoiceItem<string>[];
  abstract getCardLabels(): CardLabel[];
  abstract getCompletedTabLabels(): CardLabel[];
  abstract getAvailableSectionList(): string[];
  abstract getContentCatchphraseMaxLength(): MaxLength;
  abstract getIpPromotionVideoTitleMaxLength(): number;
  abstract getIpPromotionVideoSubtitleMaxLength(): number;
  abstract getSpecialMatchingTitleMaxLength(): number;
  abstract getCustomLabelMaxLength(): number;
  abstract getCampaignPageTitleMaxLength(): number;
  abstract getCampaignPageModuleTitleMaxLength(): number;
  abstract getCampaignPageModuleTitleRowLength(): number;
  abstract getCampaignPageModuleSubTitleMaxLength(): number;
  abstract getCampaignPageModuleSubTitleRowLength(): number;
  abstract getRewardCautionInCampaignNoticeModule(): string;
  abstract getEventCautionInCampaignNoticeModule(): string;
}
