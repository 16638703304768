<template>
  <div class="ma-2">
    <v-btn
      v-show="visible"
      v-scroll="onScroll"
      fab
      fixed
      bottom
      right
      outlined
      color="primary"
      @click="goTop"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref } from "vue";

const instance = getCurrentInstance();
const visible = ref(false);
function onScroll(): void {
  if (typeof window === "undefined") return;
  const top = window.pageYOffset || 0;
  visible.value = top > 20;
}
function goTop(): void {
  instance?.proxy.$vuetify.goTo(0);
}
</script>

<style scoped></style>
