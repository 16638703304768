import "./scss/common.scss";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "@/plugins/vue-i18n"; // NOTE: 반드시 vuetify 전에 import
import vuetify from "@/plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { PiniaVuePlugin } from "pinia";
import pinia from "@/store";
import { envs } from "@/const/dotenv";

Vue.use(PiniaVuePlugin);

if (envs.SENTRY_DSN && envs.ENVIRONMENT !== "local") {
  Sentry.init({
    Vue: Vue,
    dsn: envs.SENTRY_DSN,
    environment: envs.ENVIRONMENT,
    integrations: [new Sentry.Replay()],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  pinia,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
