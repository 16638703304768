import Vue from "vue";
import Vuetify from "vuetify";
import i18n from "@/plugins/vue-i18n";

Vue.use(Vuetify);

const defaultColors = {
  light: {
    primary: "#1976D2",
    secondary: "#424242",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
  dark: {
    primary: "#2196F3",
    secondary: "#424242",
    accent: "#FF4081",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {},
  },
  lang: {
    t: (key: string, ...params: Array<string | number>) =>
      i18n.t(key, params).toString(),
  },
  theme: {
    dark: true,
    themes: {
      light: {
        ...defaultColors.light,
        create: defaultColors.light.success,
        read: defaultColors.light.info,
        update: defaultColors.light.success,
        delete: defaultColors.light.error,
        focus: defaultColors.light.primary,
      },
      dark: {
        ...defaultColors.dark,
        create: defaultColors.dark.success,
        read: defaultColors.dark.info,
        update: defaultColors.dark.success,
        delete: defaultColors.dark.error,
        focus: "#ffffff",
      },
    },
    options: {
      customProperties: true,
    },
  },
});
