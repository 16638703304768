export default Object.freeze({
  en: {
    appVersionManagement: "App Version Management",
    appVersionInfo: {
      titleHint: "Manage by version name",
      storeVersion: "Latest release version",
      versionName: "Version name",
      minVersion: "Minimum supported version",
      versionNotice: "Display 'app updates recommended' popup",
      cancel: "Cancel",
      save: "Save",
      storeVersionHint1:
        "Enter the latest version name that can be downloaded from the store (red dot will appear on the app information)",
      storeVersionHint2:
        "'App updates recommended' popup available for use when selected for visibility",
      storeVersionHint3:
        "Build version higher than the latest version is considered to be 'in review' status, thus some features(cash friends, etc.) will not be displayed",
    },
  },
  ko: {
    appVersionManagement: "앱버전 관리",
    appVersionInfo: {
      titleHint: "버전명으로 관리합니다.",
      storeVersion: "최신 배포버전",
      versionName: "버전명",
      minVersion: "최소 대응버전",
      versionNotice: "앱 업데이트 유도팝업 노출",
      cancel: "취소",
      save: "저장",
      storeVersionHint1:
        "스토어 다운로드 가능한 최신 버전명 입력 (앱정보 레드닷 표시 활용)",
      storeVersionHint2: "노출 여부 선택시 앱 업데이트 유도팝업 사용 가능",
      storeVersionHint3:
        "최신 배포버전 이상 빌드는 심사중으로 판단하여 일부 기능 미노출 됨(캐시프렌즈 등)",
    },
  },
});
