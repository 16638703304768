const AUTH = "auth";
const CONTENT_SERVICE = "content";
const CURATION_SERVICE = "curation";
const CAMPAIGN_SERVICE = "campaign";
const REDEEM_SERVICE = "redeem";
const CLOUD_SERVICE = "cloud";
const MEDIAPROCESSING_SERVICE = "mediaprocessing";
const EPISODE_SERVICE = "episode";
const DECORATOR_SERVICE = "decorator";
const SECTION_SERVICE = "section";
const MEMBER_SERVICE = "member";
const COMMENT_SERVICE = "comment";
const BILLING_SERVICE = "billing";
const APP_VERSION_SERVICE = "app-version";
const KAKAOPAGE_SERVICE = "kakaopage";
const BULK_WAIT_FOR_FREE = "bulk-wait-for-free";

export default Object.freeze({
  AUTH: {
    token: `/${AUTH}/token`,
    permissions: `/${AUTH}/permissions/`,
    users: `/${AUTH}/users/`,
    roles: `/${AUTH}/roles/`,
    cognitoUsers: `/${AUTH}/cognito-users/`,
  },
  CONTENT: {
    contents: `/${CONTENT_SERVICE}/contents/`,
    episodes: `/${CONTENT_SERVICE}/episodes/`,
    authors: `/${CONTENT_SERVICE}/authors/`,
    submissionEpisodes: `/${CONTENT_SERVICE}/submission-episodes/`,
    cps: `/${CONTENT_SERVICE}/cps/`,
    metadataItems: `/${CONTENT_SERVICE}/metadata-items/`,
    collections: `/${CONTENT_SERVICE}/collections/`,
    matchingCollections: `/${CONTENT_SERVICE}/matching-collections/`,
    notices: `/${CONTENT_SERVICE}/notices/`,
    revisions: `/${CONTENT_SERVICE}/revision-episodes/`,
    previousEpisodes: `/${CONTENT_SERVICE}/episodes/previous-episodes`,
    bulkWaitForFrees: `${BULK_WAIT_FOR_FREE}/bulk-wait-for-frees`,
  },
  CURATION: {
    scheduleOrders: `/${CURATION_SERVICE}/schedule-orders/`,
    cardGroups: `/${CURATION_SERVICE}/card-groups/`,
    cardGroupSchedule: `/${CURATION_SERVICE}/card-group-schedule/`,
    eventPopups: `/${CURATION_SERVICE}/event-popups/`,
    eventPopupSchedule: `/${CURATION_SERVICE}/event-popup-schedule/`,
    bridgePages: `/${CURATION_SERVICE}/bridge-pages/`,
    notice: `/${CURATION_SERVICE}/notices/`,
    faq: `/${CURATION_SERVICE}/faqs/`,
    noticePopup: `/${CURATION_SERVICE}/notice-popups/`,
  },
  CAMPAIGN: {
    presents: `/${CAMPAIGN_SERVICE}/presents/`,
    luckyDraws: `/${CAMPAIGN_SERVICE}/lucky-draws/`,
    raffles: `/${CAMPAIGN_SERVICE}/raffles/`,
    messagePromotion: `/${CAMPAIGN_SERVICE}/message-promotions/`,
    messagePromotionTester: `/${CAMPAIGN_SERVICE}/message-promotion-testers/`,
    redeemCampaign: `/${CAMPAIGN_SERVICE}/redeem-campaigns/`,
    ticketDiscount: `/${CAMPAIGN_SERVICE}/ticket-discounts/`,
    giftboxTicket: `/${CAMPAIGN_SERVICE}/giftbox-tickets/`,
    questCampaign: `/${CAMPAIGN_SERVICE}/quest-campaigns/`,
    campaignPages: `/${CAMPAIGN_SERVICE}/campaign-pages/`,
    segmentTicker: `/${CAMPAIGN_SERVICE}/segment-tickers/`,
    giftPromotion: `/${CAMPAIGN_SERVICE}/gift-promotions/`,
    target: `/${CAMPAIGN_SERVICE}/targets/`,
    redeemCampaignV2: `/${REDEEM_SERVICE}/`,
  },
  CLOUD: {
    presignedUrl: `/${CLOUD_SERVICE}/presigned-upload-url`,
    mediaPresignedUrl: `/${CLOUD_SERVICE}/media-presigned-upload-url`,
    objects: `/${CLOUD_SERVICE}/objects`,
  },
  MEDIAPROCESSING: {
    universeThumbnail: `/${MEDIAPROCESSING_SERVICE}/universe-thumbnail`,
    searchThumbnail: `/${MEDIAPROCESSING_SERVICE}/search-thumbnail`,
    sharingThumbnail: `/${MEDIAPROCESSING_SERVICE}/sharing-thumbnail`,
  },
  EPISODE: {
    bm: `/${EPISODE_SERVICE}/content-bm/`,
    createPayBM: `/${EPISODE_SERVICE}/content-bm/create-pay-bm`,
    createWaitForFreeBM: `/${EPISODE_SERVICE}/content-bm/create-wait-for-free-bm`,
    createEarlyAccessBM: `/${EPISODE_SERVICE}/content-bm/create-early-access-bm`,
    createPayAndEarlyAccessBM: `/${EPISODE_SERVICE}/content-bm/create-pay-and-early-access-bm`,
    createWaitForFreeAndEarlyAccessBM: `/${EPISODE_SERVICE}/content-bm/create-wait-for-free-and-early-access-bm`,
    createPayAndWaitForFreeFixBM: `/${EPISODE_SERVICE}/content-bm/create-pay-and-wait-for-free-fix-bm`,
    createWaitForFreeAndWaitForFreeFixBM: `/${EPISODE_SERVICE}/content-bm/create-wait-for-free-and-wait-for-free-fix-bm`,
    createFreeBM: `/${EPISODE_SERVICE}/content-bm/create-free-bm`,
    waitForFree: `/${EPISODE_SERVICE}/wait-for-free`,
    contents: `/${EPISODE_SERVICE}/contents/`,
    ticketPackage: `/${EPISODE_SERVICE}/ticket-package/`,
    deleteTicketPackageAll: `/${EPISODE_SERVICE}/ticket-package/inactive-all`,
    welcomeGift: `/${EPISODE_SERVICE}/welcome-gift/`,
    episodes: `/${EPISODE_SERVICE}/episodes/`,
    forceFreeEpisode: `/${EPISODE_SERVICE}/force-free-episode/`,
    removeForceFreeEpisode: `/${EPISODE_SERVICE}/remove-force-free-episode/`,
    saveForceFreeEpisode: `/${EPISODE_SERVICE}/save-force-free-episode/`,
    calculateDelay: `/${EPISODE_SERVICE}/calculate-delay/`,
    delayEarlyAccess: `/${EPISODE_SERVICE}/delay-early-access/`,
    episodeBM: `/${EPISODE_SERVICE}/episode-bm`,
    nextEarlyAccessEndDate: `/${EPISODE_SERVICE}/episode-bm/next-early-access-end-date`,
  },
  SECTION: {
    sections: `/${SECTION_SERVICE}/sections/`,
    pinBanners: `/${SECTION_SERVICE}/pin-banners`,
  },
  DECORATOR: {
    contents: `/${DECORATOR_SERVICE}/contents/`,
  },
  BILLING: {
    billing: `/${BILLING_SERVICE}/`,
  },
  MEMBER: {
    users: `${MEMBER_SERVICE}/users/`,
    nicknameProhibition: `${MEMBER_SERVICE}/nickname-prohibition`,
    devices: `${MEMBER_SERVICE}/devices/`,
    comment: `${MEMBER_SERVICE}/comments/users/`,
    rewardBlock: "reward-block-user/",
  },
  COMMENT: {
    users: `${COMMENT_SERVICE}/comments/users/`,
    comment: `/${COMMENT_SERVICE}/comments`,
  },
  APP_VERSION: {
    appVersions: `${APP_VERSION_SERVICE}/app-versions/`,
  },
  KAKAOPAGE: {
    authToken: `/${KAKAOPAGE_SERVICE}/auth/token`,
    episodes: `/${KAKAOPAGE_SERVICE}/episodes/`,
    uploadEpisodeImage: `/${KAKAOPAGE_SERVICE}/upload-episode-image`,
    contents: `/${KAKAOPAGE_SERVICE}/contents/`,
  },
});
