import type { ServiceLanguageString } from "@/common/types";
import { defineStore } from "pinia";

export interface ServiceLanguage {
  lang: ServiceLanguageString | "";
}

export const useServiceLanguageStore = defineStore("serviceLanguage", {
  state: (): ServiceLanguage => {
    return {
      lang: "",
    };
  },
  actions: {},
  persist: true,
});
