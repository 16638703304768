<template>
  <v-navigation-drawer v-model="value" fixed app class="leftDrawer">
    <v-list dense>
      <template
        v-for="item in menuItems.filter(
          (_item) =>
            hasPermission(_item) &&
            isAvailableRegion(_item) &&
            isAvailableEnv(_item),
        )"
      >
        <v-list-group
          v-if="item.children"
          :key="item.text"
          :prepend-icon="item.icon"
          :value="false"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children.filter(
              (_item) =>
                hasPermission(_item) &&
                isVdi(_item) &&
                isAvailableRegion(_item) &&
                isAvailableEnv(_item),
            )"
            :key="i"
            @click="go(child)"
          >
            <v-list-item-action v-if="!child.children">
              <v-icon v-if="child.icon" v-text="child.icon" />
            </v-list-item-action>
            <v-list-item-content v-if="!child.children">
              <v-list-item-title
                :class="
                  child.path &&
                  route.fullPath.startsWith(child.path) &&
                  'primary--text text--lighten-1 font-weight-black'
                "
                v-text="child.text"
              />
            </v-list-item-content>
            <v-list-item-action v-if="!child.children && child.endIcon">
              <v-icon v-text="child.endIcon" />
            </v-list-item-action>

            <v-list-group
              v-if="child.children"
              :key="child.text"
              :prepend-icon="child.icon"
              :value="false"
              no-action
              sub-group
            >
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title v-text="child.text" />
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(grandchild, _i) in child.children.filter(
                  (_item) =>
                    hasPermission(_item) &&
                    isAvailableRegion(_item) &&
                    isAvailableEnv(_item),
                )"
                :key="_i"
                @click="go(grandchild)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="grandchild.text" />
                </v-list-item-content>
                <v-list-item-action v-if="grandchild.endIcon">
                  <v-icon v-text="grandchild.endIcon" />
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else-if="!item.children"
          :key="item.text"
          @click="go(item)"
        >
          <v-list-item-action>
            <v-icon v-text="item.icon" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import type { TranslateResult } from "vue-i18n";
import type { Dictionary } from "vue-router/types/router";
import { METADATA_TYPE } from "@/views/cms/metadata-item/common/choices";
import type { Nullable } from "@/common/types";
import { Env } from "@/common/types";
import type { PermissionString } from "@/common/permissions";
import { Permission } from "@/common/permissions";
import { envs } from "@/const/dotenv";
import { Region } from "@/views/cms/content/common/choices";
import type { AppVersionRoutes } from "@/views/app-version/common/types";
import i18n from "@/plugins/vue-i18n";
import { useVModel } from "@vueuse/core";
import { computed, watch } from "vue";
import { cloneDeep } from "lodash-es";
import { useServiceLanguageStore } from "@/store/modules/service-language";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/modules/auth";
import { useRoute } from "vue-router/composables";
import { pushRouter, replaceRouter } from "@/router/common";
import { stringifyParams } from "@/utils/api";

interface MenuItem {
  text: TranslateResult;
  icon?: string;
  endIcon?: string;
  path?: string;
  query?: Dictionary<string | Nullable<string>[] | null | undefined>;
  children?: MenuItem[];
  permission?: PermissionString;
  availableRegions?: string[];
  availableEnvs?: string[];
}

const props = defineProps<{ value: boolean }>();
const emits = defineEmits<{ (e: "input", v: boolean): void }>();
const value = useVModel(props, "value", emits, { eventName: "input" });
const menuItems: MenuItem[] = [
  { text: i18n.t("menu.home"), icon: "home", path: "/" },
  {
    icon: "collections",
    text: i18n.t("menu.cms"),
    permission: Permission.MENU_CMS,
    children: [
      {
        text: i18n.t("menu.contents"),
        path: "/contents",
        permission: Permission.READ_CONTENT_AUTHOR,
      },
      {
        text: i18n.t("menu.waitForFreeBulkChange"),
        path: "/wait-for-free-bulk-change",
        permission: Permission.READ_CONTENT_AUTHOR,
        availableRegions: [Region.THAILAND, Region.TAIWAN, Region.INDONESIA],
      },
      {
        text: i18n.t("menu.author"),
        path: "/authors",
        permission: Permission.READ_CONTENT_AUTHOR,
      },
      {
        text: i18n.t("menu.submissionEpisodes"),
        path: "/submission-episodes",
        permission: Permission.READ_CONTENT_AUTHOR,
        availableRegions: [Region.KOREA],
      },
      {
        text: i18n.t("menu.metadata"),
        permission: Permission.READ_METADATA_ITEM,
        children: METADATA_TYPE.getChoices().map((metadata) => {
          return {
            text: metadata.text,
            path: "/metadata-items",
            query: {
              metadata: metadata.value,
            },
          };
        }),
      },
    ],
  },
  {
    icon: "mdi-developer-board",
    text: i18n.t("menu.sms"),
    permission: Permission.MENU_SMS,
    children: [
      {
        text: i18n.t("menu.sections"),
        path: "/sections",
      },
      {
        text: i18n.t("menu.eventPopup"),
        path: "/event-popups",
      },
      {
        text: i18n.t("menu.bridgePage"),
        path: "/bridge-pages",
      },
      {
        text: i18n.t("menu.matching"),
        path: "/matching-collections",
      },
    ],
  },
  {
    icon: "mdi-target-account",
    text: i18n.t("menu.reward"),
    permission: Permission.MENU_REWARD,
    children: [
      {
        text: i18n.t("menu.present"),
        path: "/presents",
        permission: Permission.READ_EDIT_PRESENT,
      },
      {
        text: i18n.t("menu.raffle"),
        path: "/raffles",
        permission: Permission.READ_EDIT_RAFFLE,
      },
      {
        text: i18n.t("menu.luckyDraw"),
        path: "/lucky-draws",
        permission: Permission.READ_EDIT_LUCKY_DRAW,
      },
    ],
  },
  {
    icon: "mdi-crosshairs-gps",
    text: i18n.t("menu.crm"),
    permission: Permission.MENU_CRM,
    children: [
      {
        text: i18n.t("menu.messagePromotion"),
        path: "/message-promotions",
        permission: Permission.READ_MSG_PROMO,
      },
      {
        text: i18n.t("menu.segmentTicker"),
        path: "/segment-tickers",
        permission: Permission.READ_SEGMENT_TICKER,
      },
      {
        text: i18n.t("menu.questCampaign"),
        path: "/quest-campaigns",
        permission: Permission.READ_QUEST_CAMPAIGN,
      },
      {
        text: `${i18n.t("menu.redeemCodeCampaign")} v1`,
        path: "/redeem-campaigns",
        permission: Permission.READ_REDEEM_CAMPAIGN,
      },
      {
        text: `${i18n.t("menu.redeemCodeCampaign")} v2`,
        path: "/v2/redeem-campaigns",
        permission: Permission.READ_REDEEM_CAMPAIGN,
      },
      {
        text: i18n.t("menu.contentDiscountCampaign"),
        path: "/ticket-discounts",
        permission: Permission.READ_TICKET_DISCOUNT_CAMPAIGN,
      },
      {
        text: i18n.t("menu.ticketGiftbox"),
        path: "/giftbox-tickets",
        permission: Permission.READ_TICKET_GIFT_BOX,
      },
      {
        text: i18n.t("menu.giftPromotion"),
        path: "/gift-promotions?id=1",
        permission: Permission.READ_GIFT_PROMOTION,
        availableRegions: [Region.KOREA],
      },
    ],
  },
  {
    icon: "mdi-alert-octagram-outline",
    text: i18n.t("menu.noticeCs"),
    permission: Permission.MENU_NOTICE_CS,
    children: [
      {
        text: i18n.t("menu.notice"),
        path: "/notices",
        permission: Permission.READ_EDIT_NOTICE,
      },
      {
        text: i18n.t("menu.faq"),
        path: "/faqs",
        permission: Permission.READ_EDIT_FAQ,
      },
      {
        text: i18n.t("menu.noticePopup"),
        path: "/notice-popups",
        permission: Permission.READ_EDIT_POPUP_NOTIFICATION,
      },
    ],
  },
  {
    icon: "mdi-account",
    text: i18n.t("menu.memberManagement"),
    permission: Permission.MENU_MEMBER,
    children: [
      {
        text: i18n.t("menu.memberSearch"),
        path: "/member-search",
        permission: Permission.READ_MEMBER,
      },
      {
        text: i18n.t("menu.serviceBlockUserManagement"),
        path: "/service-block",
        permission: Permission.READ_BLOCKED_MEMBER,
      },
      {
        text: i18n.t("menu.rewardBlockUserManagement"),
        path: "/reward-block",
        permission: Permission.EDIT_BLOCKED_REWARD,
        availableRegions: [Region.THAILAND, Region.TAIWAN, Region.INDONESIA],
      },
      {
        text: i18n.t("menu.nicknameProhibitionManagement"),
        path: "/nickname-prohibition",
        permission: Permission.READ_EDIT_PROHIBITION_NICKNAME,
      },
    ],
  },
  {
    icon: "mdi-comment-search-outline",
    text: i18n.t("menu.commentSystem"),
    permission: Permission.MENU_COMMENT,
    children: [
      {
        text: i18n.t("menu.commentReport"),
        path: "/reports",
        permission: Permission.READ_EDIT_REPORT_COMMENT,
        availableRegions: [Region.THAILAND, Region.TAIWAN, Region.INDONESIA],
      },
      {
        text: i18n.t("menu.comment"),
        path: "/comments",
        permission: Permission.READ_EDIT_COMMENT,
      },
      {
        text: i18n.t("menu.commentBannedUser"),
        path: "/banned-users",
        permission: Permission.READ_EDIT_COMMENT_BLOCK_USER,
      },
      {
        text: i18n.t("menu.commentBannedWord"),
        path: "/banned-words",
        permission: Permission.READ_EDIT_PROHIBITION_COMMENT,
        availableRegions: [Region.THAILAND, Region.TAIWAN, Region.INDONESIA],
      },
    ],
  },
  {
    icon: "mdi-account-outline",
    text: i18n.t("menu.userManagement"),
    permission: Permission.EDIT_ADMIN,
    children: [
      {
        text: i18n.t("menu.users"),
        path: "/users",
        permission: Permission.REGISTER_ADMIN,
      },
      {
        text: i18n.t("menu.roles"),
        path: "/roles",
        permission: Permission.EDIT_ADMIN,
      },
    ],
  },
  {
    icon: "mdi-apps",
    text: i18n.t("menu.appVersion"),
    permission: Permission.MENU_APP_VERSION,
    children: [
      {
        text: i18n.t("menu.korProdAppVersion"),
        path: `/${"kor-prod-app-version" as AppVersionRoutes}`,
        permission: Permission.READ_EDIT_APP_VERSION,
        availableEnvs: [Env.PROD, Env.LOCAL],
      },
      {
        text: i18n.t("menu.globalProdAppVersion"),
        path: `/${"global-prod-app-version" as AppVersionRoutes}`,
        permission: Permission.READ_EDIT_APP_VERSION,
        availableEnvs: [Env.PROD, Env.LOCAL],
      },
      {
        text: i18n.t("menu.korQAAppVersion"),
        path: `/${"kor-qa-app-version" as AppVersionRoutes}`,
        permission: Permission.READ_EDIT_APP_VERSION,
      },
      {
        text: i18n.t("menu.globalQAAppVersion"),
        path: `/${"global-qa-app-version" as AppVersionRoutes}`,
        permission: Permission.READ_EDIT_APP_VERSION,
      },
      {
        text: i18n.t("menu.korSandboxAppVersion"),
        path: `/${"kor-sandbox-app-version" as AppVersionRoutes}`,
        permission: Permission.READ_EDIT_APP_VERSION,
      },
      {
        text: i18n.t("menu.globalSandboxAppVersion"),
        path: `/${"global-sandbox-app-version" as AppVersionRoutes}`,
        permission: Permission.READ_EDIT_APP_VERSION,
      },
    ],
    availableRegions: [Region.KOREA],
  },
  {
    icon: "mdi-tools",
    text: i18n.t("menu.util"),
    permission: Permission.READ_EDIT_UTILITY,
    children: [
      {
        text: i18n.t("menu.converter"),
        path: "/converter",
      },
    ],
  },
];

const { permissions, clientIp } = storeToRefs(useAuthStore());

function hasPermission(menuItem: MenuItem): boolean {
  if (envs.SKIP_AUTH) {
    return true;
  }
  return (
    !menuItem.permission ||
    (Array.isArray(permissions.value) &&
      permissions.value.includes(menuItem.permission))
  );
}

function isAvailableRegion(menuItem: MenuItem): boolean {
  if (!menuItem.availableRegions) {
    return true;
  }

  return menuItem.availableRegions.includes(envs.SERVICE_REGION_CODE);
}

function isAvailableEnv(menuItem: MenuItem): boolean {
  if (!menuItem.availableEnvs) {
    return true;
  }

  return !!menuItem.availableEnvs.find((env) => envs.ENVIRONMENT.includes(env));
}

function isVdi(menuItem: MenuItem): boolean {
  if (
    envs.SERVICE_REGION_CODE !== Region.KOREA ||
    !envs.ENVIRONMENT.includes(Env.PROD)
  ) {
    return true;
  }

  if (
    menuItem.permission === Permission.REGISTER_ADMIN ||
    menuItem.permission === Permission.EDIT_ADMIN
  ) {
    return (
      clientIp.value.startsWith("172.16.110.") ||
      clientIp.value.startsWith("172.16.111.")
    );
  }

  return true;
}

const route = useRoute();

async function go(menuItem: MenuItem) {
  if (
    menuItem.path &&
    (route.fullPath !== menuItem.path ||
      (menuItem.query &&
        JSON.stringify(route.query) !== JSON.stringify(menuItem.query)))
  ) {
    if (menuItem.query) {
      await pushRouter(`${menuItem.path}?${stringifyParams(menuItem.query)}`);
    } else {
      await pushRouter(menuItem.path);
    }
  }
}

const flattenMenuItems = computed((): string[] =>
  getFlatMenuItems(menuItems, permissions.value).map((m) => m.path ?? "-"),
);

function getFlatMenuItems(
  menuItem: MenuItem[],
  permissions: string[],
  parentPermission?: string,
): MenuItem[] {
  if (!menuItem || menuItem.length === 0) {
    return [];
  }
  const cloneRoleMenuMaps = cloneDeep(menuItem);
  let result: MenuItem[] = [];
  for (const _menuItem of cloneRoleMenuMaps) {
    if (
      (_menuItem.permission && permissions.includes(_menuItem.permission)) ||
      (parentPermission && permissions.includes(parentPermission))
    ) {
      if ((_menuItem.children ?? []).length > 0) {
        result = [
          ...result,
          ...getFlatMenuItems(
            _menuItem.children ?? [],
            permissions,
            _menuItem.permission,
          ),
        ];
        _menuItem.children = [];
      }
      result = [...result, _menuItem];
    }
  }
  return result;
}
const { lang } = useServiceLanguageStore();
watch(
  () => [route.path, lang],
  async () => {
    if (
      !envs.SKIP_AUTH &&
      flattenMenuItems.value.every(
        (m) => !route.path.startsWith(m) && !route.fullPath.startsWith(m),
      )
    ) {
      await replaceRouter("/");
    }
  },
  { immediate: true },
);
</script>

<style scoped>
.leftDrawer {
  will-change: initial;
}
</style>
