<template>
  <v-app id="inspire">
    <navigation-drawer v-if="signedIn" v-model="drawer" />

    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-text="$t('msg.kakaoWebtoon')" />
      <v-spacer />

      <v-toolbar-title>{{ time }}</v-toolbar-title>
      <v-divider vertical inset class="ml-5 mr-3" />
      <span v-if="serviceLanguage" class="text-right"
        >{{ serviceLanguage.toUpperCase() }}<br />
        {{ serviceLanguageText }}</span
      >

      <v-divider vertical inset class="ml-5 mr-3" />

      <span v-if="signedIn">
        <v-toolbar-title class="d-inline" v-text="staffId" />

        <v-menu offset-y min-width="300">
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list subheader dense>
            <v-subheader>Settings</v-subheader>

            <v-list-item>
              <v-list-item-title>{{
                $t("msg.adminLanguage")
              }}</v-list-item-title>
              <v-list-item-subtitle>
                <toolbar-admin-language />
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>{{
                $t("msg.serviceLanguage")
              }}</v-list-item-title>
              <v-list-item-subtitle>
                <toolbar-service-language />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-divider />

          <v-list dense>
            <v-list-item @click="signOut()">
              <v-list-item-content>
                <v-list-item-title v-text="$t('msg.signOut')" />
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>exit_to_app</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-app-bar>

    <v-main>
      <v-container v-if="!signedIn" fluid fill-height>
        <v-layout justify-center align-center>
          <v-btn
            color="grey"
            class="ma-2 white--text"
            @click="openSignInPage()"
            v-text="$t('msg.signIn')"
          />
        </v-layout>
      </v-container>

      <router-view v-if="signedIn"></router-view>
    </v-main>

    <v-footer app />

    <go-top-button />
  </v-app>
</template>

<script setup lang="ts">
import NavigationDrawer from "@/components/app/NavigationDrawer.vue";
import GoTopButton from "@/components/app/GoTopButton.vue";
import { envs } from "@/const/dotenv";
import ToolbarAdminLanguage from "@/components/toolbar/admin/ToolbarAdminLanguage.vue";
import { axiosInstance, generateToken } from "@/plugins/axios";
import type { Token } from "@/store/modules/auth";
import { useAuthStore } from "@/store/modules/auth";
import { tzDayjs } from "@/utils/tzDayjs/index";
import {
  computed,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useIntervalFn } from "@vueuse/core";
import ToolbarServiceLanguage from "@/components/toolbar/language/ToolbarServiceLanguage.vue";
import type { ServiceLanguageString } from "@/common/types";
import { SERVICE_LANGUAGE, ServiceLanguage } from "@/common/types";
import { serviceRegion } from "@/const/service/init";
import { patchUserLastLanguage } from "@/views/auth/user/common/api";
import { storeToRefs } from "pinia";
import { useKakaopageAuthStore } from "@/store/modules/kakaopage-auth";
import { useAdminLanguageStore } from "@/store/modules/admin-language";
import { useServiceLanguageStore } from "@/store/modules/service-language";
import { useRoute, useRouter } from "vue-router/composables";

const drawer = ref(true);
const time = ref(tzDayjs().toDatetimeString());

const { pause } = useIntervalFn(() => {
  time.value = tzDayjs().toDatetimeString();
}, 1_000);

const { accessToken, expirationTime, lastLanguage, refreshToken, staffId } =
  storeToRefs(useAuthStore());
const { clearToken, setToken, clearPermissions } = useAuthStore();
const { clearKakaopageUserToken } = useKakaopageAuthStore();
const { lang: adminLanguage } = storeToRefs(useAdminLanguageStore());
const { lang: serviceLanguage } = storeToRefs(useServiceLanguageStore());

const serviceLanguageText = computed(() =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  SERVICE_LANGUAGE.getText(serviceLanguage.value),
);

const signedIn = computed((): boolean => {
  if (envs.SKIP_AUTH) {
    return true;
  }

  return (
    !!accessToken.value && expirationTime.value > Math.floor(Date.now() / 1000)
  );
});

function signOut(): void {
  clearToken();
  clearKakaopageUserToken();
  window.location.replace(envs.COGNITO_LOGOUT_ENDPOINT);
}

function openSignInPage(): void {
  window.location.replace(envs.COGNITO_LOGIN_ENDPOINT);
}

const router = useRouter();
const route = useRoute();

async function authenticate(authorizationCode: string): Promise<void> {
  const response = await axiosInstance.post<Token>("/auth/token", {
    code: authorizationCode,
  });

  if (response.status === 200) {
    setToken(response.data);
    clearKakaopageUserToken();

    if (route.path !== "/") {
      await router.replace({
        path: "/",
      });
    }
  }
}

function hackUrl(): void {
  /**
   * cognito OAuth2 를 통해 로그인 시 운영툴로 redirect 하는데 cognito 에선 fragment url(#) 을 redirect url 로 지정 불가능하다.
   * https://admin.com/?code=1d29-ffac-c703e#/ 형태로 불완전하게 redirect 되는데 이를 바로 잡고,
   * 인증에 필요한 authorization code 도 올바르게 쿼리로 넘긴다.
   */
  if (location.href.indexOf("/#/") === -1) {
    // const params = new URL(location.href).searchParams;
    // const code = params.get("code");
    const split = location.href.split("/?code=");
    if (split.length > 1) {
      const code = split[1].replace("#/", "");
      if (code) {
        location.href = `/#/?authCode=${code}`;
      }
    }
  }
}

onBeforeUnmount(() => pause());
watch(
  () => route.query.authCode,
  async (val) => {
    const authorizationCode = val;
    if (typeof authorizationCode === "string") {
      await authenticate(authorizationCode);
    }
  },
  { immediate: true, deep: true },
);
onBeforeMount(() => {
  if (!adminLanguage.value) {
    adminLanguage.value = ServiceLanguage.KO;
  }
  if (!serviceLanguage.value) {
    serviceLanguage.value = serviceRegion.getServiceLanguages()[0];
  }
});
onMounted(hackUrl);

async function setServiceLanguages(language: ServiceLanguageString) {
  await patchUserLastLanguage(language);
  lastLanguage.value = language;
}
watch(
  () => signedIn.value,
  async (val: boolean) => {
    if (val && !lastLanguage.value) {
      await setServiceLanguages(serviceRegion.getServiceLanguages()[0]);
    }
  },
);

watch(
  // ToolbarServiceLanguage 에서 처리해야하지만 항상 떠있는 컴포넌트가 아니기 때문에 여기로 이동해서 처리
  () => lastLanguage.value,
  async (val: ServiceLanguageString | "") => {
    if (signedIn.value && val && val !== serviceLanguage.value) {
      clearPermissions();
      serviceLanguage.value = val;
      const response = await generateToken(refreshToken.value);
      setToken(response.data);
      axiosInstance.defaults.headers.common["Authorization"] =
        "Bearer " + accessToken.value;
      await nextTick();
      location.reload();
    }
    serviceLanguage.value = val;
  },
);
</script>
