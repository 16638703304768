import type { Snack } from "@/store/types";
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarning,
} from "@/utils/alerts";
import { defineStore } from "pinia";

export const useSnackStore = defineStore("snack", {
  actions: {
    pushSnack(snack: Snack): void {
      const message = snack.text?.toString() ?? "";
      switch (snack.color) {
        case "success":
          toastSuccess(message, snack.timeout).then();
          return;
        case "warning":
          toastWarning(message, snack.timeout).then();
          return;
        case "error":
          toastError(message, snack.timeout).then();
          return;
        default:
        case "info":
          toastInfo(message, snack.timeout).then();
          return;
      }
    },
  },
});
