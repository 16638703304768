import { ConfigByServiceLanguage } from "@/const/service/language";
import type { CardLabel } from "@/views/sms/section/common/types/featured";
import type { ChoiceItem } from "@/common/types";
import type { MaxLength } from "@/const/service/common/types";

export class Thai extends ConfigByServiceLanguage {
  getGenreChoices(): ChoiceItem<string>[] {
    return [
      { text: "โรแมนซ์แฟนตาซี", value: "ROMANCE_FANTASY" },
      { text: "โรแมนซ์", value: "ROMANCE" },
      { text: "แอ็กชัน", value: "ACTION" },
      { text: "ดราม่า", value: "DRAMA" },
      { text: "วาย", value: "BL_GL" },
      { text: "สยองขวัญ", value: "THRILLER_HORROR" },
    ];
  }

  getCardLabels(): CardLabel[] {
    return [
      // RED
      {
        text: "ใหม่",
        backgroundColor: "#FF3042",
      },
      {
        text: "จบบริบูรณ์",
        backgroundColor: "#FF3042",
      },
      {
        text: "ฟรี ทุก 3 วัน",
        backgroundColor: "#FF3042",
      },
      {
        text: "ใหม่ แนววาย",
        backgroundColor: "#FF3042",
      },
      {
        text: "เรื่องใหม่",
        backgroundColor: "#FF3042",
      },
      {
        text: "เรื่องใหม่ที่รอคอย",
        backgroundColor: "#FF3042",
      },
      {
        text: "ตอนฟรีเพียบ",
        backgroundColor: "#FF3042",
      },
      {
        text: "ใหม่ แนวสยองขวัญ",
        backgroundColor: "#FF3042",
      },
      {
        text: "ตอนใหม่",
        backgroundColor: "#FF3042",
      },
      {
        text: "ฟรี ทุก 12 ชม.",
        backgroundColor: "#FF3042",
      },
      {
        text: "ใหม่ แนวแอ็กชัน",
        backgroundColor: "#FF3042",
      },
      {
        text: "ซีซันกลับมา",
        backgroundColor: "#FF3042",
      },
      {
        text: "ฟรี ทุก 1 วัน",
        backgroundColor: "#FF3042",
      },
      {
        text: "ใหม่ แนวโรแมนซ์",
        backgroundColor: "#FF3042",
      },
      {
        text: "จบซีซัน",
        backgroundColor: "#FF3042",
      },
      {
        text: "ฟรี ทุก 2 วัน",
        backgroundColor: "#FF3042",
      },
      {
        text: "ใหม่ แนวดราม่า",
        backgroundColor: "#FF3042",
      },
      // GOLD
      {
        text: "กิจกรรม",
        backgroundColor: "#C6A66D",
      },
      {
        text: "ตั๋วของขวัญ",
        backgroundColor: "#C6A66D",
      },
      {
        text: "ของรางวัลจริง",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Gift",
        backgroundColor: "#C6A66D",
      },
      {
        text: "ตั๋ว",
        backgroundColor: "#C6A66D",
      },
      {
        text: "แคช",
        backgroundColor: "#C6A66D",
      },
    ];
  }

  getCompletedTabLabels(): CardLabel[] {
    return [
      { text: "ตำนาน", backgroundColor: "#FF3042" }, // 레전드
      { text: "แนะนำโดย PD", backgroundColor: "#FF3042" }, // PD추천
      { text: "ตัวอย่างภาพยนตร์", backgroundColor: "#FF3042" }, // 영화 예고편
      { text: "แนะนำ", backgroundColor: "#FF3042" }, // 추천
      { text: "แม็กกาซีน", backgroundColor: "#FF3042" }, // 매거진
      { text: "ตั๋วเช่า", backgroundColor: "#FF3042" }, // 대여권
      { text: "แคชแบ็ก", backgroundColor: "#FF3042" }, // 캐시백
    ];
  }

  getAvailableSectionList(): string[] {
    return [
      "top_hot_main",
      "top_hot_normal",
      "gidamoo_plus_hot",
      "main_featured",
      "main_featured_last",
      "gidamoo_plus_new",
      "main_gidamoo",
      "main_special",
      "channel_mon",
      "channel_tue",
      "channel_wed",
      "channel_thu",
      "channel_fri",
      "channel_sat",
      "channel_sun",
      "channel_completed",
      "ad_event_viewer",
      "ad_content_home",
      "ad_rank_all",
      "timetable_mon",
      "timetable_tue",
      "timetable_wed",
      "timetable_thu",
      "timetable_fri",
      "timetable_sat",
      "timetable_sun",
      "timetable_completed",
    ];
  }

  getContentCatchphraseMaxLength(): MaxLength {
    return {
      threeLines: 25,
      twoLines: 30,
    };
  }

  getIpPromotionVideoTitleMaxLength(): number {
    return 16;
  }

  getIpPromotionVideoSubtitleMaxLength(): number {
    return 25;
  }

  getSpecialMatchingTitleMaxLength(): number {
    return 20;
  }

  getCustomLabelMaxLength(): number {
    return 0;
  }

  getCampaignPageTitleMaxLength(): number {
    return 20;
  }
  getCampaignPageModuleTitleMaxLength(): number {
    return 23;
  }
  getCampaignPageModuleTitleRowLength(): number {
    return 2;
  }
  getCampaignPageModuleSubTitleMaxLength(): number {
    return 35;
  }
  getCampaignPageModuleSubTitleRowLength(): number {
    return 4;
  }
  getRewardCautionInCampaignNoticeModule(): string {
    return "เงื่อนไขในการเข้าร่วมกิจกรรม";
  }
  getEventCautionInCampaignNoticeModule(): string {
    return (
      "หากมีตั๋วที่แลกไว้แล้ว สามารถใช้ได้โดยไม่ต้องแลกใหม่\n" +
      // eslint-disable-next-line quotes
      'สามารถตรวจสอบรางวัลที่ได้รับ และวันหมดอายุของรางวัล ได้ที่หน้า "แจ้งเตือน>ข่าวสารของฉัน"\n' +
      "ของรางวัลไม่สามารถแลกเปลี่ยนเป็นเงินสดได้\n" +
      "การตัดสินของกรรมการถือเป็นที่สิ้นสุด"
    );
  }
}
