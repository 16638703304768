import router from "@/router";
import { stringifyParams } from "@/utils/api";
import { parse } from "qs";

function formatPath(val: string): string {
  const _path = val.split("?");
  const uri = _path.splice(0, 1);
  return uri + stringifyParams(parse(_path.join("?")));
}

export async function replaceRouter(path: string): Promise<void> {
  // navigationDuplicated 에러를 피하기 위한것
  if (formatPath(router.currentRoute.fullPath) === formatPath(path)) {
    return;
  }

  await router.replace(path);
}
export async function pushRouter(path: string): Promise<void> {
  // navigationDuplicated 에러를 피하기 위한것
  if (formatPath(router.currentRoute.fullPath) === formatPath(path)) {
    return;
  }

  await router.push(path);
}
