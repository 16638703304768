import { defineStore } from "pinia";

export interface KakaopageUserToken {
  tokenType: string;
  accessToken: string;
  expires: string;
  createdDateTime?: number;
}

interface KakaopageUserTokenState extends KakaopageUserToken {
  createdDateTime: number;
}

// const getters = {
//   kakaopageAccessToken: (): string => {
//     return this.accessToken;
//   },
//   kakaopageExpires: (): string => {
//     return this.expires;
//   },
//   kakaopageUserTokenCreatedDateTime: (
//     ,
//   ): number => {
//     return this.createdDateTime;
//   },
//   kakaopageUserToken: (): KakaopageUserToken => {
//     return state;
//   },
// };

export const useKakaopageAuthStore = defineStore("kakaopageAuth", {
  state: (): KakaopageUserTokenState => {
    return {
      tokenType: "",
      accessToken: "",
      expires: "",
      createdDateTime: 0,
    };
  },
  actions: {
    setKakaopageUserToken(token: KakaopageUserToken): void {
      this.tokenType = token.tokenType;
      this.accessToken = token.accessToken;
      this.expires = token.expires;
      this.createdDateTime = Date.now();
    },
    clearKakaopageUserToken(): void {
      this.tokenType = "";
      this.accessToken = "";
      this.expires = "";
      this.createdDateTime = 0;
    },
  },
  persist: true,
});
