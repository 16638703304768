import { ServiceRegion } from "@/const/service/region";
import i18n from "@/plugins/vue-i18n";
import { SEARCH_TYPE, SearchType } from "@/views/member/search/common/choices";
import { Channel, CONTENT_CHANNEL } from "@/views/cms/content/common/choices";
import type { AgeChoiceItem } from "@/const/service/common/types";
import type { ChoiceItem } from "@/common/types";
import type { FilterItem } from "@/components/filter/common/types";
import type { ServiceLanguageString } from "@/common/types";
import {
  combinationContentBMFilterItems,
  singleContentBMFilterItems,
} from "@/components/filter/common/items";
import { ServiceLanguage } from "@/common/types";

export class Korea extends ServiceRegion {
  getAgeChoices(): AgeChoiceItem[] {
    return [
      { text: i18n.t("cms.ageLimit.all").toString(), value: 0, color: "green" },
      {
        text: "12",
        value: 12,
        color: "blue",
      },
      {
        text: "15",
        value: 15,
        color: "orange",
      },
      {
        text: i18n.t("cms.ageLimit.adult").toString(),
        value: 19,
        color: "red",
      },
    ];
  }

  getAdultAge(): number {
    return 19;
  }

  getChannelChoices(): { text: string; value: Channel }[] {
    return CONTENT_CHANNEL.getChoices();
  }

  genreRankingEnabled(): boolean {
    return true;
  }

  ch2Enabled(): boolean {
    return true;
  }

  possessionTicketEnabled(): boolean {
    return true;
  }

  getMemberSearchType(): ChoiceItem<string>[] {
    return Object.values(SEARCH_TYPE.getChoices()).filter((choice) =>
      [
        SearchType.USER_ID,
        SearchType.ACCOUNT_ID,
        SearchType.DAUM_ID,
        SearchType.DAUM_USER_ID,
        SearchType.APP_USER_ID,
        SearchType.EMAIL,
      ].includes(choice.value as SearchType),
    );
  }

  getMemberSearchTypeHint(): Partial<Record<SearchType, string>> {
    return {
      [SearchType.USER_ID]: i18n
        .t("member.search.searchTypeHints.USER_ID_HINT")
        .toString(),
      [SearchType.ACCOUNT_ID]: i18n
        .t("member.search.searchTypeHints.ACCOUNT_ID_HINT")
        .toString(),
      [SearchType.DAUM_ID]: i18n
        .t("member.search.searchTypeHints.DAUM_ID_HINT")
        .toString(),
      [SearchType.DAUM_USER_ID]: i18n
        .t("member.search.searchTypeHints.DAUM_USER_ID_HINT")
        .toString(),
      [SearchType.APP_USER_ID]: i18n
        .t("member.search.searchTypeHints.APP_USER_ID_HINT")
        .toString(),
      [SearchType.EMAIL]: i18n
        .t("member.search.searchTypeHints.KOR_EMAIL_HINT")
        .toString(),
    };
  }

  getMemberDataShow(): boolean {
    return true;
  }

  changeNormalBannerTextColorEnabled(): boolean {
    return true;
  }

  adEnabled(): boolean {
    return true;
  }

  webToAppEnabled(): boolean {
    return true;
  }

  bannerEventSpecialCardMaxLength(): number {
    return 3;
  }

  getServiceLanguages(): ServiceLanguageString[] {
    return [ServiceLanguage.KO];
  }

  combinationBMEnabled(): boolean {
    return true;
  }

  sendManuscriptToolEnabled(): boolean {
    return true;
  }

  getContentBMFilterItems(): FilterItem[] {
    return singleContentBMFilterItems().concat(
      combinationContentBMFilterItems(),
    );
  }

  showReleasedForFreeDateTime(): boolean {
    return true;
  }

  showKakaopageEpisodeGet(): boolean {
    return true;
  }

  showKakaopageOriginalNovelContentLink(): boolean {
    return true;
  }

  gidamooPlusEnabled(): boolean {
    return false;
  }

  freeTodayEnabled(): boolean {
    return true;
  }

  freePublishingBadgeEnabled(): boolean {
    return true;
  }

  getHotMainCardSubTitleMaxLength(): number {
    return 24;
  }

  getHotMainCardTitleTextMaxLength(): number {
    return 13;
  }

  showMemberAgeCredentials(): boolean {
    return false;
  }
}
