<template>
  <div>
    <v-container fluid>
      <v-card class="mx-auto" :max-width="MAX_SCREEN_WIDTH">
        <transition name="fade" mode="out-in">
          <router-view :key="path"></router-view>
        </transition>
      </v-card>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { MAX_SCREEN_WIDTH } from "@/const/options";
import { useRoute } from "vue-router/composables";

const { path } = useRoute();
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
