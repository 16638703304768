import { locale, Message, MessageBox } from "vuetify-pro-dialog";
import i18n from "@/plugins/vue-i18n";
import Vuetify from "@/plugins/vuetify";
import { nextTick } from "vue";

locale.set("ko_KR");

export async function toastSuccess(
  message: string,
  timer = 2_000,
): Promise<void> {
  Message.success(message, {
    timeout: timer,
    position: "bottom",
    showIcon: true,
    color: Vuetify.framework.theme.themes.dark.success as string,
  });
  await applyMultipleLineSnackbarContent(message);
  await resetColor();
}

export async function toastInfo(message: string, timer = 3_000): Promise<void> {
  Message.notify.info(message, {
    timeout: timer,
    position: "top",
    showIcon: true,
    color: Vuetify.framework.theme.themes.dark.info as string,
  });
  await applyMultipleLineSnackbarContent(message);
  await resetColor();
}

export async function toastWarning(
  message: string,
  timer = 3_000,
): Promise<void> {
  Message.notify.warning(message, {
    timeout: timer,
    position: "top",
    showIcon: true,
    color: Vuetify.framework.theme.themes.dark.warning as string,
  });
  await applyMultipleLineSnackbarContent(message);
  await resetColor();
}

export async function toastError(
  message: string,
  timer = 60_000,
): Promise<void> {
  Message.notify.error(message, {
    timeout: timer,
    position: "top",
    showIcon: true,
    color: Vuetify.framework.theme.themes.dark.error as string,
  });
  await applyMultipleLineSnackbarContent(message);
  await resetColor();
}

async function applyMultipleLineSnackbarContent(message: string) {
  if (!message?.includes("\n")) {
    return;
  }
  await nextTick();
  const selectors = document.querySelectorAll(".v-snack__content");
  selectors?.forEach((selector) => {
    if (selector?.innerHTML) {
      selector.innerHTML = selector.innerHTML.split("\n").join("<br>");
    }
  });
}

// export function toastCloseAll(): void {
//   Message.closeAll();
// }

async function resetColor(): Promise<void> {
  // Message 가 띄워지고 난 후 body 태그의 속성에 Vuetify 에서 제공하는 기본 색상외
  // 개발자가 설정한 create, read, update, delete, focus theme color 가 삭제되어
  // 일부 색상이 없어지게 되는 현상이 발생.
  // admin 운영툴이 아닌 다른 시스템에서 확인해봤지만 여기서 시스템에서만 발생을 하며
  // 추후 업데이트를 진행할 때 이 부분을 제거 할 것.
  Vuetify.framework.theme.dark = false;
  await nextTick();
  Vuetify.framework.theme.dark = true;
}

export async function confirm(
  title: string,
  text: string,
  confirmButtonText = i18n.t("msg.confirm").toString(),
  cancelButtonText = i18n.t("msg.cancel").toString(),
): Promise<boolean> {
  return MessageBox.confirm(text, title, {
    type: "info",
    icon: "mdi-alert-octagram",
    showIcon: true,
    actions: {
      true: { text: confirmButtonText },
      false: { text: cancelButtonText },
    },
  });
}

// export async function confirmDelete(
//   text?: string,
//   title = i18n.t("msg.confirmDelete").toString(),
//   confirmButtonText = i18n.t("msg.confirm").toString(),
//   cancelButtonText = i18n.t("msg.cancel").toString(),
// ): Promise<boolean> {
//   return MessageBox.confirm(text ?? title, {
//     title: title,
//     type: "error",
//     icon: "mdi-delete-alert",
//     showIcon: true,
//     actions: {
//       true: { text: confirmButtonText },
//       false: { text: cancelButtonText },
//     },
//   });
// }

// export async function prompt(
//   title: string,
//   inputPlaceholder = i18n.t("msg.required").toString(),
// ): Promise<string | undefined> {
//   let text: string | undefined;
//   await MessageBox.prompt(title, {
//     title: title,
//     type: "error",
//     icon: "mdi-keyboard",
//     textFieldProps: {
//       placeholder: inputPlaceholder,
//     },
//     showIcon: true,
//     actions: {
//       true: { text: i18n.t("msg.confirm").toString() },
//       false: { text: i18n.t("msg.cancel").toString() },
//     },
//     beforeClose: (v: string) => {
//       text = v;
//       return new Promise((resolve) => {
//         setTimeout(() => resolve(true), 2000);
//       });
//     },
//   });
//   return text;
// }
