import { ConfigByServiceLanguage } from "@/const/service/language";
import type { CardLabel } from "@/views/sms/section/common/types/featured";
import type { ChoiceItem } from "@/common/types";
import type { MaxLength } from "@/const/service/common/types";

export class English extends ConfigByServiceLanguage {
  getGenreChoices(): ChoiceItem<string>[] {
    return [
      { text: "Romance", value: "ROMANCE" },
      { text: "Romance Fantasy", value: "ROMANCE_FANTASY" },
      { text: "Drama", value: "DRAMA" },
      { text: "Action", value: "ACTION" },
      { text: "Horror, Thriller", value: "THRILLER_HORROR" },
      { text: "BL, GL", value: "BL_GL" },
    ];
  }

  getCardLabels(): CardLabel[] {
    return [
      {
        text: "Upcoming",
        backgroundColor: "#FF3042",
      },
      {
        text: "New",
        backgroundColor: "#FF3042",
      },
      {
        text: "Season Return",
        backgroundColor: "#FF3042",
      },
      {
        text: "Season 2",
        backgroundColor: "#FF3042",
      },
      {
        text: "Season 3",
        backgroundColor: "#FF3042",
      },
      {
        text: "Season 4",
        backgroundColor: "#FF3042",
      },
      {
        text: "Season 5",
        backgroundColor: "#FF3042",
      },
      {
        text: "Completed",
        backgroundColor: "#FF3042",
      },
      {
        text: "Season Completed",
        backgroundColor: "#FF3042",
      },
      {
        text: "Popular",
        backgroundColor: "#FF3042",
      },
      {
        text: "Trending",
        backgroundColor: "#FF3042",
      },
      {
        text: "Upcoming",
        backgroundColor: "#C6A66D",
      },
      {
        text: "New",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Season Return",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Season 2",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Season 3",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Season 4",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Season 5",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Upcoming Season",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Completed",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Season Completed",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Early Bird Registration",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Gift Pass",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Flash Event",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Cash Discount",
        backgroundColor: "#C6A66D",
      },
      {
        text: "2hr & Free",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Check-in",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Event",
        backgroundColor: "#C6A66D",
      },
    ];
  }

  getCompletedTabLabels(): CardLabel[] {
    return [
      { text: "레전드", backgroundColor: "#FF3042" },
      { text: "PD추천", backgroundColor: "#FF3042" },
      { text: "영화 예고편", backgroundColor: "#FF3042" },
      { text: "추천", backgroundColor: "#FF3042" },
      { text: "매거진", backgroundColor: "#FF3042" },
      { text: "대여권", backgroundColor: "#FF3042" },
      { text: "캐시백", backgroundColor: "#FF3042" },
    ];
  }

  getAvailableSectionList(): string[] {
    return [
      "top_hot_main",
      "top_hot_normal",
      "gidamoo_plus_hot",
      "main_featured",
      "main_featured_last",
      "gidamoo_plus_new",
      "main_gidamoo",
      "main_special",
      "channel_mon",
      "channel_tue",
      "channel_wed",
      "channel_thu",
      "channel_fri",
      "channel_sat",
      "channel_sun",
      "channel_completed",
      "ad_event_viewer",
      "ad_content_home",
      "ad_rank_all",
    ];
  }

  getContentCatchphraseMaxLength(): MaxLength {
    return {
      threeLines: 25,
      twoLines: 35,
    };
  }

  getIpPromotionVideoTitleMaxLength(): number {
    return 0;
  }

  getIpPromotionVideoSubtitleMaxLength(): number {
    return 0;
  }

  getSpecialMatchingTitleMaxLength(): number {
    return 20;
  }

  getCustomLabelMaxLength(): number {
    return 0;
  }

  getCampaignPageTitleMaxLength(): number {
    return 20;
  }
  getCampaignPageModuleTitleMaxLength(): number {
    return 25;
  }
  getCampaignPageModuleTitleRowLength(): number {
    return 2;
  }
  getCampaignPageModuleSubTitleMaxLength(): number {
    return 35;
  }
  getCampaignPageModuleSubTitleRowLength(): number {
    return 4;
  }
  getRewardCautionInCampaignNoticeModule(): string {
    return "Rewards will be distributed after completing the mission. It may take between 1~3 business days for the rewards to appear on your account.";
  }
  getEventCautionInCampaignNoticeModule(): string {
    return (
      "Rewards will expire 1 day from the date received.\n" +
      "Rewards not used during this period will automatically expire.\n" +
      "This event may be rescheduled or canceled without prior notice."
    );
  }
}
