import type { FilterItem } from "@/components/filter/common/types";
import i18n from "@/plugins/vue-i18n";
import { BM } from "@/views/cms/content/common/choices";

export function singleContentBMFilterItems(): FilterItem[] {
  return [
    {
      type: "checkbox",
      text: i18n.t("filter.bm.PAY").toString(),
      value: BM.pay,
      checked: false,
    },
    {
      type: "checkbox",
      text: i18n.t("filter.bm.WAIT_FOR_FREE").toString(),
      value: BM.wait_for_free,
      checked: false,
    },
    {
      type: "checkbox",
      text: i18n.t("filter.bm.WAIT_FOR_FREE_FIX").toString(),
      value: BM.wait_for_free_fix,
      checked: false,
    },
    {
      type: "checkbox",
      text: i18n.t("filter.bm.EARLY_ACCESS").toString(),
      value: BM.early_access,
      checked: false,
    },
    {
      type: "checkbox",
      text: i18n.t("filter.bm.FREE").toString(),
      value: BM.free,
      checked: false,
    },
  ];
}

export function combinationContentBMFilterItems(): FilterItem[] {
  return [
    {
      type: "checkbox",
      text: i18n.t("filter.bm.RERUN_SERIES").toString(),
      value: BM.rerun_series,
      checked: false,
    },
    {
      type: "checkbox",
      text: i18n.t("filter.bm.PAY_AND_EARLY_ACCESS").toString(),
      value: BM.pay_and_early_access,
      checked: false,
    },
    {
      type: "checkbox",
      text: i18n.t("filter.bm.WAIT_FOR_FREE_AND_EARLY_ACCESS").toString(),
      value: BM.wait_for_free_and_early_access,
      checked: false,
    },
    {
      type: "checkbox",
      text: i18n.t("filter.bm.PAY_AND_WAIT_FOR_FREE_FIX").toString(),
      value: BM.pay_and_wait_for_free_fix,
      checked: false,
    },
    {
      type: "checkbox",
      text: i18n.t("filter.bm.WAIT_FOR_FREE_AND_WAIT_FOR_FREE_FIX").toString(),
      value: BM.wait_for_free_and_fix,
      checked: false,
    },
  ];
}
