<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template #activator="{ on }">
      <v-btn text class="ml-0" v-on="on">
        <span
          v-text="
            // @ts-ignore
            SERVICE_LANGUAGE.getText(serviceLanguage)
          "
        />
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-list dense nav>
      <v-list-item
        v-for="language in availableLanguages"
        :key="language.value"
        @click="setServiceLanguages(language.value)"
      >
        <v-list-item-title v-text="language.text" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { serviceRegion } from "@/const/service/init";
import { SERVICE_LANGUAGE } from "@/common/types";
import type { ServiceLanguageString } from "@/common/types";
import { patchUserLastLanguage } from "@/views/auth/user/common/api";
import { storeToRefs } from "pinia";
import { useServiceLanguageStore } from "@/store/modules/service-language";

const availableLanguages = computed(() =>
  SERVICE_LANGUAGE.getChoices().filter((v) =>
    serviceRegion.getServiceLanguages().includes(v.value),
  ),
);

const { lang: serviceLanguage } = storeToRefs(useServiceLanguageStore());
async function setServiceLanguages(language: ServiceLanguageString) {
  await patchUserLastLanguage(language);
  serviceLanguage.value = language;
}
</script>
