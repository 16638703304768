export default Object.freeze({
  en: {
    cancel: "Cancel",
    apply: "Apply",
    contentStatus: {
      contentStatus: "Content status",
      BEING_PUBLISHED: {
        BEING_PUBLISHED: "Unissued",
        inputting: "Unissued - entering",
        bmInputCompleted: "Unissued - BM entered",
        ableToOrder: "Unissued - available",
        reservationForSale: "Unissued - ready for 'on sale'",
      },
      SELLING: {
        SELLING: "On sale",
        reservationForStoppingSale:
          "On sale - ready for 'temporarily unavailable'",
      },
      STOP_SELLING: {
        STOP_SELLING: "Temporarily Unavailable ",
        reservationForSelling: "Temporarily Unavailable - ready for 'on sale'",
      },
      PROHIBITED: "Unavailable",
    },
    bm: {
      bm: "BM",
      PAY: "Paid",
      EARLY_ACCESS: "Early Access",
      WAIT_FOR_FREE: "Wait For Free",
      WAIT_FOR_FREE_FIX: "Wait For Free (fixed)",
      RERUN_SERIES: "Rerun",
      PAY_AND_EARLY_ACCESS: "Paid + Early Access",
      WAIT_FOR_FREE_AND_EARLY_ACCESS: "Wait For Free + Early Access",
      PAY_AND_WAIT_FOR_FREE_FIX: "Paid + Wait For Free (fixed)",
      WAIT_FOR_FREE_AND_WAIT_FOR_FREE_FIX:
        "Wait For Free + Wait For Free (fixed)",
      FREE: "Free",
    },
    others: "and other",
    count: "",
    ongoingStatus: {
      ongoingStatus: "Serial Status",
      N_A: "N/A",
      EPISODES_PUBLISHING: "Ongoing",
      EPISODES_NOT_PUBLISHING: "Hiatus",
      END_OF_SEASON: "Season complete (short-term)",
      SEASON_COMPLETED: "Season complete (long-term)",
      COMPLETED: "Completed",
    },
    serviceBlock: {
      blockReason: {
        blockReason: "Reason for blocking",
      },
      blockStatus: {
        blockStatus: "Block status",
      },
    },
  },
  ko: {
    cancel: "취소",
    apply: "적용",
    contentStatus: {
      contentStatus: "작품 상태",
      BEING_PUBLISHED: {
        BEING_PUBLISHED: "발행 전",
        inputting: "발행 전 - 입력중",
        bmInputCompleted: "발행 전 - BM 입력완료",
        ableToOrder: "발행 전 - 예약가능",
        reservationForSale: "발행 전 - 판매 예약중",
      },
      SELLING: {
        SELLING: "판매중",
        reservationForStoppingSale: "판매중 - 판매중지 예약중",
      },
      STOP_SELLING: {
        STOP_SELLING: "판매중지",
        reservationForSelling: "판매중지 - 판매중 예약중",
      },
      PROHIBITED: "판매금지",
    },
    bm: {
      bm: "BM",
      PAY: "유료",
      EARLY_ACCESS: "미리보기",
      WAIT_FOR_FREE: "기다무",
      WAIT_FOR_FREE_FIX: "기다무(고정)",
      RERUN_SERIES: "재연재",
      PAY_AND_EARLY_ACCESS: "유료 + 미리보기",
      WAIT_FOR_FREE_AND_EARLY_ACCESS: "기다무 + 미리보기",
      PAY_AND_WAIT_FOR_FREE_FIX: "유료 + 기다무(고정)",
      WAIT_FOR_FREE_AND_WAIT_FOR_FREE_FIX: "기다무 + 기다무(고정)",
      FREE: "무료",
    },
    others: "외",
    count: "개",
    ongoingStatus: {
      ongoingStatus: "연재 상태",
      N_A: "해당없음",
      EPISODES_PUBLISHING: "연재 중",
      EPISODES_NOT_PUBLISHING: "휴재 중",
      END_OF_SEASON: "시즌 완결(단기)",
      SEASON_COMPLETED: "시즌 완결(장기)",
      COMPLETED: "완결",
    },
    serviceBlock: {
      blockReason: {
        blockReason: "차단 사유",
      },
      blockStatus: {
        blockStatus: "차단 상태",
      },
    },
  },
});
