export default Object.freeze({
  en: {
    comment: {
      listPageTitle: "Comments",
      listPageTitleHint:
        "Search and manage user's comments on content / event page.",
      bannedStatus: "Block status",
      bannedStatusCommand: "Block/Unblock",
      banForever: "Block permanently",
      ban: "Block",
      inBan: "Blocked",
      unban: "Unblock",
      category: "Category",
      commentText: "Comment contents",
      createdDt: "Date of comment",
      blockingDate: "Blocking date",
      reportCount: "Total number of reports",
      status: "Comment status",
      inspectStatus: "Review status",
      commentBlockTitle: "Block posting comments",
      commentUnblockTitle: "Unblock posting comments",
      commentCancelBlockTitle: "Unblock posting comments",
      commentReportDetailTitle: "Report details",
      commentDetailTitle: "Comment details",
      reportUserId: "Reported user_id",
      reportType: "Reason for reporting",
      reportText: "Reported content",
      reportDateTime: "Reported date",
      commentUserId: "Commenter",
      isCommentBlock: "Comment ability status",
      firstReportDt: "Initial report date",
      likeCount: "Likes",
      dislikeCount: "dislikes",
      userId: "user_id",
      hideCommentCount: "Number of regulated comments",
      banReason: "Reason for blocking",
      commentBlockSaveText1:
        "When manually blocked, user's block duration is determined by total number of regulated comments.",
      commentUnblockSaveText1:
        "When manually unblocked, The end-of-block date is reflected when the unblock was applied.",
      commentBlockSaveText2:
        "1st level block will be applied for users with less than 10 regulated comments.",
      commentUnblockSaveText2:
        "Depending on the number of regulatory comments the user has at the time of Unblock, the blocking level is changed or maintained at the same time as it is released.",
      commentBlockSaveText3: "Block user from commenting?",
      commentUnblockSaveText3: "Unblock user from commenting?",
      choices: {
        COMMENT_INSPECT_STATUS: {
          RECEIPT: "Registered",
          COMPLETE: "Reviewed",
        },
        COMMENT_STATUS: {
          NORMAL: "Public",
          HIDE: "Hidden",
          AUTO_HIDE: "Auto-hide",
          TEMPORARY: "Temporarily blocked comment",
          DELETE: "Delete",
          ADMIN_DELETE: "Admin delete",
          BANNED_WORD: "Prohibited words",
        },
        COMMENT_FILTER_CATEGORY: {
          ALL: "All",
          CONTENT: "Content",
          EPISODE: "Episode",
          EVENT: "Event page",
        },
        COMMENT_USER_KEY_TYPE: {
          uid: "User ID",
          nickname: "Nickname",
        },
        COMMENT_BANNED_USER_TYPE: {
          BAN: "Block",
          BAN_END: "Block canceled",
          UNBAN: "Unblock",
          INFINITY: "Block permanently",
        },
        COMMENT_REPORT_TYPE: {
          comment_report_spam: "Spam",
          comment_report_illegal: "Illegal ",
          comment_report_sexual: "Sexually explicit",
          comment_report_violence: "Violence/Harmful behavior",
          comment_report_privacy: "Personal Information Disclosure",
          comment_report_repeatedly: "Repeatedly posting same comment",
          comment_report_rights: "Infringement of rights",
          comment_report_piracy: "Online Pricy",
          comment_report_etc: "Something Else",
        },
      },
    },
    report: {
      listPageTitle: "Reported comments",
      listPageTitleHint: "Monitor and manage reported comments.",
    },
    user: {
      listPageTitle: "Blocked commenters",
      listPageTitleHint: "Manage blocked commenters.",
      banStep: "Block levels",
      bannedUserId: "Blocked user",
      hideCommentCount: "Number of regulated comments",
      reason: "Reason for blocking",
      banOption: "Block option",
      banPeriod: "Block duration",
      bannedFrom: "From ",
      bannedTo: "To",
      bannedStatus: "Block status",
      bannedStatusCommand: "Block/Unblock",
      commentBlockDetailTitle: "Regulated comment history",
      goToCommentPage: "View details  (Go to comments menu)",
      commentBlockHistoryTitle: "History of blocked comment posting",
      guideTitle: "Comment block policy",
      guideText1:
        "Number of regulated comments determine the 9 levels of blocked status.",
      guideText2:
        "1st level: 10 / 2nd level: 15 / 3rd level: 18 / 4th level: 20 / 5th level: 21 / 6th level: 22 / 7th level: 23 / 8th level: 24 / 9th level: 25 and above",
      guideText3: "Comment block duration for each level is as follows:",
      guideText4:
        "1st level: 7days / 2nd level: 15d / 3rd level: 30d / 4th level: 60d / 5th level: 90d / 6th level: 120d / 7th level: 150d / 8th level: 180d / 9th level: block permanently",
      guideText5:
        "1st level block will be automatically applied from the time the user has 10 regulated comments.",
      choices: {
        BAN_STEP: {
          _0: "Not blocked",
          _1: "1st level block",
          _2: "2nd level block",
          _3: "3rd level block",
          _4: "4th level block",
          _5: "5th level block",
          _6: "6th level block",
          _7: "7th level block",
          _8: "8th level block",
          _9: "9th level block",
        },
        BANNED_USER_STATUS: {
          BAN: "Block",
          BAN_END: "Block canceled",
          UNBAN: "Unblocked",
          INFINITY: "Block permanently",
        },
        BAN_OPTION_TYPES: {
          AUTO_BAN: "Auto",
          ADMIN_BAN: "Manual (by operator)",
          MANUAL_BAN: "Manual",
        },
      },
    },
    word: {
      listPageTitle: "Prohibited words",
      listPageTitleHint: "Register and manage prohibited words for comment.",
      word: "Prohibited word",
      createdDateTime: "Created date",
      addWord: "Register prohibited word",
      existWord: "This keyword has already been registered.",
      failExistWords: "Already registered keywords cannot be saved.",
      readyStatus: "Ready",
      saveStatus: "Saved",
      failStatus: "Save failed",
    },
  },
  ko: {
    comment: {
      listPageTitle: "댓글 관리",
      listPageTitleHint:
        "작품 및 이벤트 페이지에 작성된 댓글을 조회하고 관리합니다.",
      bannedStatus: "차단 상태",
      bannedStatusCommand: "차단/해제",
      banForever: "영구차단",
      ban: "차단",
      inBan: "차단중",
      unban: "해제",
      category: "카테고리",
      commentText: "댓글 내용",
      createdDt: "작성 일자",
      blockingDate: "차단 일자",
      reportCount: "누적 신고 수",
      status: "댓글 상태",
      inspectStatus: "검수 상태",
      commentBlockTitle: "댓글 작성 차단",
      commentUnblockTitle: "댓글 작성 차단 해제",
      commentCancelBlockTitle: "댓글 작성 차단 해제",
      commentReportDetailTitle: "신고 상세 내역",
      commentDetailTitle: "댓글 상세 내역",
      reportUserId: "user_id",
      reportType: "신고 사유",
      reportText: "신고 내용",
      reportDateTime: "신고 일자",
      commentUserId: "작성자",
      isCommentBlock: "댓글 작성 차단 여부",
      firstReportDt: "최초 신고일",
      likeCount: "좋아요",
      dislikeCount: "싫어요",
      userId: "user_id",
      hideCommentCount: "규제 댓글 수",
      banReason: "차단 사유",
      commentBlockSaveText1:
        "수동 차단을 진행할 경우, 유저가 보유 중인 규제 댓글 개수에 따라 차단 기간이 적용됩니다.",
      commentUnblockSaveText1:
        "수동 해제를 진행할 경우, 차단 종료 일자는 차단 해제가 적용된 시점으로 반영됩니다.",
      commentBlockSaveText2:
        "규제 댓글 개수가 10개 미만인 유저일 경우, 최초 차단(1단계)이 적용됩니다.",
      commentUnblockSaveText2:
        "해제 시점에 유저가 보유 중인 규제 댓글 개수에 따라, 해제와 동시에 차단 단계가 변경 또는 유지됩니다.",
      commentBlockSaveText3: "해당 유저의 댓글 작성을 차단 하겠습니까?",
      commentUnblockSaveText3: "해당 유저의 댓글 작성 차단을 해제 하겠습니까?",
      choices: {
        COMMENT_INSPECT_STATUS: {
          RECEIPT: "접수",
          COMPLETE: "검수 완료",
        },
        COMMENT_STATUS: {
          NORMAL: "일반",
          HIDE: "숨김",
          AUTO_HIDE: "자동 숨김",
          TEMPORARY: "임시조치 댓글",
          DELETE: "삭제",
          ADMIN_DELETE: "운영자 삭제",
          BANNED_WORD: "금칙어",
        },
        COMMENT_FILTER_CATEGORY: {
          ALL: "전체",
          CONTENT: "작품",
          EPISODE: "회차",
          EVENT: "이벤트 페이지",
        },
        COMMENT_USER_KEY_TYPE: {
          uid: "User ID",
          nickname: "닉네임",
        },
        COMMENT_BANNED_USER_TYPE: {
          BAN: "차단",
          BAN_END: "차단종료",
          UNBAN: "차단해제",
          INFINITY: "영구차단",
        },
        COMMENT_REPORT_TYPE: {
          comment_report_spam: "영리목적/홍보성",
          comment_report_illegal: "불법정보",
          comment_report_sexual: "음란성/선정성",
          comment_report_violence: "욕설/인신공격",
          comment_report_privacy: "개인정보노출",
          comment_report_repeatedly: "같은내용도배",
          comment_report_rights: "권리침해신고",
          comment_report_piracy: "불법촬용물 등 유통",
          comment_report_etc: "기타",
        },
      },
    },
    report: {
      listPageTitle: "신고 댓글 모니터링",
      listPageTitleHint: "신고 댓글을 모니터링하고 관리합니다.",
    },
    user: {
      listPageTitle: "댓글 차단 유저 관리",
      listPageTitleHint: "댓글 작성이 차단된 유저를 관리합니다.",
      banStep: "차단 단계",
      bannedUserId: "차단 유저",
      hideCommentCount: "규제 댓글 개수",
      reason: "차단 사유",
      banOption: "차단 옵션",
      banPeriod: "차단 기간",
      bannedFrom: "차단 시작 일자",
      bannedTo: "차단 종료 일자",
      bannedStatus: "차단 상태",
      bannedStatusCommand: "차단/차단해제",
      commentBlockDetailTitle: "상세 규제 댓글 내역",
      goToCommentPage: "상세보기(댓글관리 메뉴로 이동)",
      commentBlockHistoryTitle: "댓글 작성 차단 이력",
      guideTitle: "댓글 차단 정책",
      guideText1: "규제된 댓글 개수에 따라 9단계로 차단이 적용됩니다. ",
      guideText2:
        "1단계 : 10개 / 2단계 : 15개 / 3단계 : 18개 / 4단계 : 20개 / 5단계 : 21개 / 6단계 : 22개 / 7단계 : 23개 / 8단계 : 24개 / 9단계 : 25개 이후",
      guideText3: "각 단계별 댓글 작성 제한 기간은 아래와 같이 적용됩니다. ",
      guideText4:
        "1단계 : 7일 /  2단계 : 15일 / 3단계 : 30일 / 4단계 : 60일 / 5단계 : 90일 / 6단계 : 120일 / 7단계 : 150일 / 8단계: 180일 / 9단계 : 영구 차단",
      guideText5:
        "규제 댓글 10개를 보유한 시점부터 자동으로 최초 차단 1단계(7일 작성 제한)가 적용됩니다.",
      choices: {
        BAN_STEP: {
          _0: "차단되지 않음",
          _1: "1단계 차단",
          _2: "2단계 차단",
          _3: "3단계 차단",
          _4: "4단계 차단",
          _5: "5단계 차단",
          _6: "6단계 차단",
          _7: "7단계 차단",
          _8: "8단계 차단",
          _9: "9단계 차단",
        },
        BANNED_USER_STATUS: {
          BAN: "차단",
          BAN_END: "차단 종료",
          UNBAN: "차단 해제",
          INFINITY: "영구차단",
        },
        BAN_OPTION_TYPES: {
          AUTO_BAN: "자동 차단",
          ADMIN_BAN: "수동차단(운영자 차단)",
          MANUAL_BAN: "수동차단",
        },
      },
    },
    word: {
      listPageTitle: "금칙어 관리",
      listPageTitleHint: "댓글 금칙어를 등록 및 관리합니다.",
      word: "금칙어",
      createdDateTime: "금칙어 생성일",
      addWord: "금칙어 등록",
      existWord: "이미 등록된 키워드입니다.",
      failExistWords: "이미 등록된 키워드는 실패로 카운트 됩니다.",
      readyStatus: "준비대기",
      saveStatus: "저장됨",
      failStatus: "실패됨",
    },
  },
});
