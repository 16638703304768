<template>
  <div>
    <v-menu offset-y left transition="slide-y-transition">
      <template #activator="{ on }">
        <v-btn text class="ml-0" v-on="on">
          <span v-text="currentLocale?.label" />
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list dense nav>
        <v-list-item
          v-for="locale in computedAvailableLocales"
          :key="locale.code"
          @click="openToolbarLanguageDialog(locale.code)"
        >
          <v-list-item-title class="pl-2" v-text="locale.label" />
        </v-list-item>
      </v-list>
    </v-menu>
    <ToolbarAdminLanguageDialog ref="toolbarAdminLanguageDialog" />
  </div>
</template>

<script setup lang="ts">
import type { AdminLanguage } from "@/common/types";
import i18n, { availableLocales } from "@/plugins/vue-i18n";
import ToolbarAdminLanguageDialog from "@/components/toolbar/admin/ToolbarAdminLanguageDialog.vue";

import { computed, ref } from "vue";

const toolbarAdminLanguageDialog = ref();

const currentLocale = computed((): AdminLanguage | undefined =>
  availableLocales.find((i: AdminLanguage) => i.code === i18n.locale),
);
const computedAvailableLocales = computed((): AdminLanguage[] =>
  availableLocales.filter((i: AdminLanguage) => i.code !== i18n.locale),
);

function openToolbarLanguageDialog(locale: string): void {
  toolbarAdminLanguageDialog.value.open(locale);
}
</script>
