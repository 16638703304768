import type { RegisteredUser, User } from "@/views/auth/user/common/types";
import type { PagingListParams } from "@/utils/api";
import {
  createDataApi,
  getDataApi,
  getPagingListApi,
  patchDataApi,
  resultSnackbar,
  updateDataApi,
} from "@/utils/api";
import endpoints from "@/const/endpoints";
import type { Nullable } from "@/common/types";

export async function registerUser(
  data: RegisteredUser,
): Promise<Nullable<RegisteredUser>> {
  const result = await createDataApi<RegisteredUser>(
    endpoints.AUTH.users,
    data,
  );

  resultSnackbar(result);
  if (result.success && result.data) {
    return result.data;
  } else {
    return null;
  }
}

export async function patchUser(data: Partial<User>): Promise<Nullable<User>> {
  const result = await patchDataApi<User>(endpoints.AUTH.users + data.id, data);

  resultSnackbar(result);
  if (result.success && result.data) {
    return result.data;
  } else {
    return null;
  }
}

export async function updateUser(data: User): Promise<Nullable<User>> {
  const result = await updateDataApi<User>(
    endpoints.AUTH.users + data.id,
    data,
  );

  resultSnackbar(result);
  if (result.success && result.data) {
    return result.data;
  } else {
    return null;
  }
}

export async function getUser(id: number): Promise<Nullable<User>> {
  const result = await getDataApi<User>(endpoints.AUTH.users + id);
  if (result.success && result.data) {
    return result.data;
  } else {
    resultSnackbar(result);
    return null;
  }
}

export async function getUserList(
  params: PagingListParams,
): Promise<{ users: User[]; total: number }> {
  const result = await getPagingListApi<User>({
    url: endpoints.AUTH.users,
    ...params,
  });

  if (result.success && result.data) {
    return { users: result.data.results, total: result.data.count };
  } else {
    resultSnackbar(result);
    return { users: [], total: 0 };
  }
}

export async function patchUserLastLanguage(language: string) {
  const result = await patchDataApi<{ language: string }, User>(
    `${endpoints.AUTH.users}update-last-language`,
    { language: language },
  );

  if (!result.success) {
    resultSnackbar(result);
  }
}
