/// <reference types="." />
import { envs } from "@/const/dotenv";
import dayjs, { Dayjs } from "dayjs";
import type { Duration } from "iso8601-duration";
import { parse } from "iso8601-duration";
export default <T = unknown>(_option: T, dayjsClass: typeof Dayjs): void => {
  dayjsClass.prototype.toDatetimeString = function () {
    return this.isValid() ? this.format(envs.DATETIME_FORMAT_STRING) : "-";
  };
  dayjsClass.prototype.toDateString = function () {
    return this.isValid() ? this.format(envs.DATE_FORMAT_STRING) : "-";
  };
  dayjsClass.prototype.encodeDuration = (duration: Duration): string => {
    return dayjs.duration(duration).toISOString();
  };
  dayjsClass.prototype.decodeDuration = (durationString: string): Duration => {
    return parse(durationString);
  };
};
